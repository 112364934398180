import React, { useState, useEffect } from "react"
import { getData } from "../../services/sendData.js"
import YieldChart from "./yieldChart.js"
import Status from "./status.js"
import Facilities from "./facilities.js"
import Rooms from "./rooms.js"
import RoomView from "./room.js"
import SectionView from "./sectionView.js"
import Loading from "../utils/loading.js"
import Back from "../utils/back.js"
import AddYield from "../yield/addYield.js"
import CreateObject from "./createObject.js"
import PlantView from "./plantView.js"
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment'

import "../css/dashboard/dashboard.scss"

const Dashboard = () => {
  const [createFacility, setCreateFacility] = useState(false)
  const [createRoom, setCreateRoom] = useState(false)
  const [facilities, setFacilities] = useState([])
  const [facility, setFacility] = useState(null)
  const [rooms, setRooms] = useState(null)
  const [room, setRoom] = useState(null)
  const [roomData, setRoomData] = useState(null)
  const [section, setSection] = useState(null)
  const [sectionData, setSectionData] = useState(null)
  const [plant, setPlant] = useState(null)
  const [roomRecipe, setRoomRecipe] = useState(null)
  const [showAddYield, setShowAddYield] = useState(false)
  const [loading, setLoading] = useState(false)

  async function getUserFacilities(){
    var data = getData('getUserFacilities');
    await data.then(function(result) {
      if (result['status'] == 200) {
        setFacilities(result['data'])
        setLoading(false)
       }
    });
  }

  async function getUserRooms(index){
    if (index != undefined && facilities[index].ID > 0) {
      var data = getData('getUserRooms?facility=' + facilities[index].ID);
      await data.then(function(result) {
        if (result['status'] == 200) {
          setRooms(result['data'])
          setFacility(index)
          setLoading(false)
         }
      });
    }
  }

  function getIdentifier(col, row, height) {
    return col.toString() + "-" + row.toString() + "-" + height.toString()
  }

  async function getRoomData(index){
    if (index != undefined && rooms[index].ID > 0) {
      var room_specs = rooms[index];
      var identifiers = [];
      var promises = [];
      var room_data = {};
      for (var l = 0; l < room_specs.Levels; l++) {
        for (var c = 0; c < room_specs.Columns; c++) {
          for (var r = 0; r < room_specs.Rows; r++) {
            identifiers.push(getIdentifier(c+1, r+1, l+1));
          }
        }
      }
      var recipe_data = getData('getRoomRecipe?room=' + rooms[index].ID);
      await recipe_data.then(function(result) {
        if (result['status'] == 200) {
          setRoomRecipe(result['data'])
        }
      });
      for (var i = 0; i < identifiers.length; i++) {
        var data = getData('getRoomData?room=' + rooms[index].ID + "&identifier=" + identifiers[i]);
        promises.push(data);
        // data.then(function(result) {
        //   if (result['status'] == 200) {
        //     for (var key in result['data']) {
        //       room_data[key] = result['data'][key];
        //     }
        //   }
        // });
      }
      Promise.all(promises).then((result) => {
        for (var p = 0; p < result.length; p++) {
          var data = result[p];
          if (data['status'] == 200) {
            for (var key in data['data']) {
              room_data[key] = data['data'][key];
            }
          }
        }
        setRoomData(room_data)
        setRoom(index)
        setLoading(false)
      });
      // var data = getData('getRoomData?room=' + rooms[index].ID);
      // await data.then(function(result) {
      //   if (result['status'] == 200) {
      //     setRoomData(result['data'])
      //     setRoom(index)
      //     setLoading(false)
      //   }
      // });
    }
  }

  async function getSectionData(index){
    if (index != undefined) {
      var data = getData('getSectionData?section=' + index);
      await data.then(function(result) {
        if (result['status'] == 200) {
          setSectionData(result['data'])
          setSection(index)
          setLoading(false)
        }
      });
    }
  }

  function getRoomDataLocal(){
    return roomData
  }

  function clickFacility(index){
    setLoading(true)
    getUserRooms(index)
  }

  function clickRoom(index){
    setLoading(true)
    getRoomData(index)
  }

  function clickSection(index){
    setLoading(true)
    getSectionData(index)
  }

  function clickBackToHome(){
    setFacility(null)
    setRooms(null)
  }

  function clickBackToFacility(){
    setRoom(null)
    setRoomData(null)
    setRoomRecipe(null)
  }

  function clickBackToRoom(){
    setSection(null)
    setSectionData(null)
  }

  function clickBackToSection(){
    setPlant(null)
  }

  useEffect(() => {
    setLoading(true)
    getUserFacilities()
  }, [])

  if (loading) {
    return <Loading />
  }

  if (showAddYield) {
    var room_id = null;
    if (room != null) {
      room_id = rooms[room].ID
    }
    return <AddYield toggleAddYield={setShowAddYield} room={room_id}/>
  }

  if (createFacility) {
    return <CreateObject object="facility" hide={setCreateFacility} getter={getUserFacilities}/>
  }

  if (createRoom) {
    return <CreateObject object="room" hide={setCreateRoom} getter={() => getUserRooms(facility)} facility={facilities[facility].ID}/>
  }

  return (
    <>
      <div id="dash-content">
      <Scrollbars autoHide>
        <div id="dash-content-header">
            {
              facility === null &&
              <h3>Overview</h3>
            }
            {
              facility != null && room === null &&
              <>
                <Back click={clickBackToHome}/>
                <h3>{"Facility: " + facilities[facility].Name}</h3>
              </>
            }
            {
              facility != null && room != null && section === null &&
              <>
                <Back click={clickBackToFacility}/>
                <h3>{"Room: " + rooms[room].Name}</h3>
              </>
            }
            {
              facility != null && room != null && section != null && plant === null &&
              <>
                <Back click={clickBackToRoom}/>
                <h3>{"Section: " + section}</h3>
              </>
            }
            {
              facility != null && room != null && section != null && plant != null &&
              <>
                <Back click={clickBackToSection}/>
                <h3>{"Plant: " + plant}</h3>
              </>
            }
        </div>
        {
          section === null &&
          <>
            <div id="dash-overview">
              <div id="yield" className="dash-overview-item">
                {
                  facility === null &&
                  <YieldChart scope="whole" toggleAddYield={setShowAddYield}/>
                }
                {
                  facility != null &&
                  room === null &&
                  <YieldChart scope="facility" facility={facilities[facility].ID} toggleAddYield={setShowAddYield}/>
                }
                {
                  room != null &&
                  <YieldChart scope="room" room={rooms[room].ID} toggleAddYield={setShowAddYield}/>
                }
              </div>
              <div id="status" className="dash-overview-item">
                <Status />
              </div>
            </div>
          </>
        }
        {
          section === null &&
          <div id="facility-room-view">
            {
              facility === null &&
              room === null &&
              section === null &&
              plant === null &&
              <div id="facilities-view" className="dash-overview-item">
                <Facilities facilities={facilities} setter={clickFacility} addObject={setCreateFacility}/>
              </div>
            }
            {
              facility != null &&
              room === null &&
              section === null &&
              plant === null &&
              <div id="rooms-view" className="dash-overview-item">
                <Rooms rooms={rooms} setter={clickRoom} addObject={setCreateRoom} />
              </div>
            }
            {
              room != null &&
              section === null &&
              plant === null &&
              roomData != null &&
              <>
                <div id="room-view" className="dash-overview-item">
                  <RoomView room={rooms[room]} roomData={getRoomDataLocal} setter={clickSection} setPlant={null} recipe={roomRecipe} />
                </div>
              </>
            }
          </div>
        }
        {
            section != null &&
            plant === null &&
            <SectionView
              room={rooms[room].ID}
              identifier={section}
              trayRows={rooms[room].TrayRows}
              trayColumns={rooms[room].TrayColumns}
              roomData={getRoomDataLocal}
              setter={null}
              setPlant={setPlant}
              recipe={roomRecipe}
            />
          }
          {
            plant != null &&
            <PlantView plant={plant} roomData={getRoomDataLocal} identifier={section} room={rooms[room].ID} recipe={roomRecipe} />
          }
        </Scrollbars>
      </div>
    </>
  )
}

export default Dashboard
