import React, { useState, useEffect } from "react"
import Section from "./section.js"
import Filters from "./filters"
import { Scrollbars } from 'react-custom-scrollbars';
import EnvConsole from "./envConsole.js"
import moment from 'moment'

import "../css/dashboard/room.scss"

class Levels extends React.Component {
  getIdentifier(col, row, height) {
    return (this.props.columns-col).toString() + "-" + (row+1).toString() + "-" + height.toString()
  }

  render () {
    return (
      <>
        <div className="levels">
          <h5>Level {this.props.level}</h5>
          <div className="levels-grid">
            {
              Array.from(Array(this.props.columns), (e, i) => {
                return (
                  <div className="col">
                    <h6>{this.props.columns-i}</h6>
                    {
                      Array.from(Array(this.props.row), (a, y) => {
                        return <Section
                                  hideEnvData={false}
                                  rows={this.props.trayRows}
                                  cols={this.props.trayColumns}
                                  roomData={this.props.roomData}
                                  identifier={this.getIdentifier(i, y, this.props.level)}
                                  setter={this.props.setter}
                                  setPlant={this.props.setPlant}
                                  filter={this.props.filter}
                                  recipe={this.props.recipe}
                                  recipeValue={this.props.recipeValue}
                                  stage={this.props.stage}
                                  envConsole={this.props.envConsole}
                                  />
                      })
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }
}

const RoomView = ({room, roomData, setter, setPlant, recipe}) => {
  const [filter, setFilter] = useState(null)
  const [recipeValue, setRecipeValue] = useState(null)
  const [stageValue, setStage] = useState(null)
  const [showEnvConsole, setShowEnvConsole] = useState(false)
  const [t, setT] = useState(null)
  const [h, setH] = useState(null)
  const [co2, setCO2] = useState(null)

  function showEnvConsoleHandler(t, h, c) {
    setT(t);
    setH(h);
    setCO2(c);
    setShowEnvConsole(true)
  }

  function clickBackToRoom() {
    setT(null);
    setH(null);
    setCO2(null);
    setShowEnvConsole(false)
  }

  function grabRecipeValue() {
    if (recipe == null || recipe["Recipe"] == null) {
      return null
    }
    var start = moment(recipe["StartTime"]).local();
    var end = moment(recipe["EndTime"]).local();
    var today = moment(new Date());

    if (moment(today).isBefore(end, 'day') && moment(start).isBefore(today, 'day')) {
      var number_of_weeks_from_start = moment.duration(today.diff(start));
      var diff_days = number_of_weeks_from_start.asDays();
      var num_weeks = Math.floor(diff_days/7)
      var week_data = recipe["Recipe"]["GrowCycle"]["weeks"][num_weeks];
      var stage = week_data["stage"];
      setStage(stage);
      var globals = recipe["Recipe"]["GrowCycle"]["globals"][stage];
      if (filter == "temperature") {
        return [globals['temp-low'], globals['temp-high']]
      } else if (filter == "humidity") {
        return [globals['hum-low'], globals['hum-high']]
      } else if (filter == "co2") {
        return [week_data['co2']]
      } else if (filter == "all-air") {
        var tempAvg = (parseInt(globals['temp-low']) + parseInt(globals['temp-high']))/2;
        var humAvg = (parseInt(globals['hum-low']) + parseInt(globals['hum-high']))/2;
        var avg = (tempAvg + humAvg + parseInt(week_data['co2']))/3;
        return [avg];
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    setRecipeValue(grabRecipeValue(filter))
  }, [filter])

  if(showEnvConsole) {
    return <EnvConsole clickBackToRoom={clickBackToRoom} temp={t} hum={h} co2={co2}/>
  }

  return (
    <>
      <div id="room-view">
        <Scrollbars className="section-scroll">
          <div id="room-view-content">
            <Filters setFilter={setFilter} filter={filter}/>
            <h5>Trays</h5>
            {
              room.Levels > 0 &&
              Array.from(Array(room.Levels), (e, i) => {
                return <Levels
                          level={i+1}
                          columns={room.Columns}
                          row={room.Rows}
                          trayRows={room.TrayRows}
                          trayColumns={room.TrayColumns}
                          roomData={roomData}
                          setter={setter}
                          setPlant={setPlant}
                          filter={filter}
                          recipe={recipe}
                          recipeValue={recipeValue}
                          stage={stageValue}
                          envConsole={showEnvConsoleHandler}
                        />
              })
            }
          </div>
        </Scrollbars>
      </div>
    </>
  )
}

export default RoomView
