import React, { useState, useEffect } from "react"
import { Line, Doughnut, Radar } from 'react-chartjs-2'
import { getData } from "../../services/sendData.js"
import Loader from "react-loader-spinner";
import { getUser } from "../../services/auth.js"

import "../css/dashboard/yieldChart.scss"

var userType = getUser().userType;

/*
TODO:
 - Line data differentiate different types
 - Time series (line) options for source and type breakdowns
*/
const YieldChart = ({scope, facility, room, toggleAddYield, user_id, hideAddYield=false}) => {
  const [chartType, setChartType] = useState('line')
  const [yieldData, setYieldData] = useState(null);
  const [lineData, setLineData] = useState({})
  const [sourceData, setSourceData] = useState([])
  const [typeData, setTypeData] = useState([])
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState('1M')

  function processBasicData(data) {
    var amount = []
    var date = []
    for (var i = 0; i < data.length; i++) {
      amount.push(data[i].Amount);
      var dateParse = new Date(data[i].CreatedAt)
      date.push((dateParse.getMonth() + 1) + '/' + dateParse.getDate() + '/' +  dateParse.getFullYear());
    }
    var formatedData = {
      "amount": amount,
      "date": date
    }
    setLineData(formatedData);
  }

  function processBreakdown(data, result) {
    var amount = []
    var name = []
    var color = []

    for (const [key, value] of Object.entries(data)) {
      name.push(key)
      amount.push(value)
      color.push("#" + Math.floor(Math.random()*16777215).toString(16))
    }
    var formatedData = {
      "amount": amount,
      "names": name,
      "colors": color
    }
    result(formatedData)
  }

  function processData(data) {
    processBasicData(data["basic"])
    if (scope != "room") {
      processBreakdown(data["breakdown"], setSourceData)
    }
    processBreakdown(data["breakdownType"], setTypeData)
  }

  async function getUserYieldData(timeFrame='1M'){
    var getUrl = 'getUserYields?time=' + timeFrame + '&scope=' + scope
    if (user_id != undefined) {
      getUrl += '&user_id=' + user_id
    }
    if (scope === 'facility') {
      getUrl += '&facility=' + facility
    }
    if (scope === 'room') {
      getUrl += '&room=' + room
    }
    var data = getData(getUrl);
    await data.then(function(result) {
      if (result['status'] == 200) {
        setYieldData(result['data'])
        processData(result['data'])
        setLoading(false)
       }
    });
  }

  function changeTimeFrame(time) {
    setLoading(true)
    setTime(time)
    getUserYieldData(time)
  }

  useEffect(() => {
    setLoading(true)
    getUserYieldData()
    return () => {
      setYieldData({});
    };
  }, [])

  const line_data = {
    labels: lineData["date"],
    datasets: [
      {
        label: 'Pounds',
        data: lineData["amount"],
        fill: false,
        backgroundColor: 'rgb(46, 204, 113)',
        borderColor: 'rgba(46, 204, 113, 0.5)',
        lineTension: 0.3,
      },
    ],
  };

  const line_options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          text: "Date",
          display: true,
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          text: "Pounds",
          display: true,
        },
        grid: {
          display: false
        }
      }
    }
  };

  const bar_config = {
    type: 'bar',
    data: line_data,
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Chart.js Bar Chart'
        }
      }
    },
  };

  const DATA_COUNT = 2;
  const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

  const source_data = {
    labels: sourceData['names'],
    datasets: [
      {
        label: 'Yield',
        data: sourceData["amount"],
        backgroundColor: sourceData["colors"],
      }
    ]
  };

  const source_config = {
    data: source_data,
  };

  const type_data = {
    labels: typeData['names'],
    datasets: [
      {
        label: 'Yield',
        data: typeData["amount"],
        fill: true,
        backgroundColor: 'rgb(46, 204, 113, 0.3)',
        borderColor: 'rgb(46, 204, 113)',
        pointBackgroundColor: 'rgb(46, 204, 113)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)'
      }
    ]
  };

  const type_config = {
    type: 'radar',
    data: type_data,
    options: {
      elements: {
        line: {
          borderWidth: 7
        }
      }
    },
  };

  return (
    <>
      <div id="yieldChart">
        <div id="yield-content">
          {
             loading ? (
              <>
                <div className="content-loader">
                  <Loader type="Oval" color="#2ecc71" height={40} width={40}/>
                </div>
              </>
            ) : (
              <>
                <div className="yield-hdr">
                  <h5>Total Yield</h5>
                  {((userType == "owner" || userType == "supervisor") && !hideAddYield) ? <button className="btn" id="add-yield" onClick={() => toggleAddYield(true)}>+ Add Yield</button> : ""}
                </div>
                <ul className="graph-select" id="graph-type">
                  <li className={chartType === "line" ? "active" : ""} onClick={() => setChartType('line')}>Basic</li>
                  {
                    scope != "room" &&
                    <li className={chartType === "source" ? "active" : ""} onClick={() => setChartType('source')}>Source</li>
                  }
                  <li className={chartType === "type" ? "active" : ""} onClick={() => setChartType('type')}>Type</li>
                </ul>
                {
                  chartType === 'line' &&
                  <>
                    {
                      lineData["amount"] != undefined && lineData["amount"].length === 0 ?
                      <div className="no-data-container">
                        <h5 className="no-data">No yield data available for this timeframe.</h5>
                      </div> :
                      <Line data={line_data} options={line_options} />
                    }
                  </>
                }
                {
                  chartType === 'source' &&
                  <>
                    {
                      sourceData["amount"] != undefined && sourceData["amount"].length === 0 ?
                      <div className="no-data-container">
                        <h5 className="no-data">No yield data available for this timeframe.</h5>
                      </div> :                      <div id="pie-chart">
                        <Doughnut data={source_data} options={source_config} />
                      </div>
                    }
                  </>
                }
                {
                  chartType === 'type' &&
                  <>
                    {
                      typeData["amount"] != undefined && typeData["amount"].length === 0 ?
                      <div className="no-data-container">
                        <h5 className="no-data">No yield data available for this timeframe.</h5>
                      </div> :                      <div id="radar-chart">
                        <Radar data={type_data} options={type_config} />
                      </div>
                    }
                  </>
                }
                <ul className="graph-select" id="time-list">
                  <li className={time === '1M' ? "active" : ""} onClick={() => changeTimeFrame('1M')}>1M</li>
                  <li className={time === '3M' ? "active" : ""}  onClick={() => changeTimeFrame('3M')}>3M</li>
                  <li className={time === '6M' ? "active" : ""}  onClick={() => changeTimeFrame('6M')}>6M</li>
                  <li className={time === '1Y' ? "active" : ""}  onClick={() => changeTimeFrame('1Y')}>1Y</li>
                  <li className={time === '5Y' ? "active" : ""}  onClick={() => changeTimeFrame('5Y')}>5Y</li>
                  <li className={time === 'All' ? "active" : ""} onClick={() => changeTimeFrame('All')}>All</li>
                </ul>
              </>
            )
          }
        </div>
      </div>
    </>
  )
}

export default YieldChart
