import React, { useState, useEffect } from "react"
import { getData } from "../../services/sendData.js"
import Loading from "../utils/loading.js"
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment'

import "../css/devices/devices.scss"

class DeviceList extends React.Component {
  render () {
    return (
      <>
        <div className="device-list">
          {
            this.props.devices === null ? (
              <>
                <div className="no-devices">
                  <h6>No devices.</h6>
                </div>
              </>
            ) : (
              <>
                {
                  Array.from(this.props.devices, (e, i) => {
                    return (
                      <div className="device">
                        <h6>{e.Name}</h6>
                        {
                          e.Facility != null &&
                          <div>Facility: {e.Facility.Name}</div>
                        }
                        {
                          e.Room != null &&
                          <div>Room: {e.Room.Name}</div>
                        }
                        {
                          e.Identifier != "" &&
                          <div>Identifier: {e.Identifier}</div>
                        }
                        {
                          <div>
                            Board ID: {e.Board}
                          </div>
                        }
                        <div>
                          Status: {e.Status.charAt(0).toUpperCase() + e.Status.slice(1)}
                          {e.Status == "online" ? <span class="dot green"></span> : "" }
                          {e.Status == "offline" ? <span class="dot red"></span> : "" }
                        </div>
                        {
                          <div>
                            Last Response: {e.LastOnline != null ? moment(e.LastOnline).local().format('M/D/YY - h:mm A') : "--"}
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </>
            )
          }
        </div>
      </>
    )
  }
}

const Devices = () => {
  const [devices, setDevices] = useState(null)
  const [loading, setLoading] = useState(false)

  async function getUserDevices(){
    var data = getData('getUserDevices')
    await data.then(function(result) {
      if (result['status'] == 200) {
        setDevices(result['data'])
       }
    });
  }

  useEffect(() => {
    setLoading(true)
    getUserDevices()
    setLoading(false)
  }, [])

  return (
    <>
      <div id="dash-content">
        {
          loading || devices == null ? (
            <>
              <Loading />
            </>
          ) : (
            devices != null &&
            <>
              <Scrollbars autoHide>
                <div id="devices">
                  <h3>Devices</h3>
                  <div className="device-stats">
                    <div id="num-devices" className="stat">
                      <h5>Number of Devices: {devices["num_devices"]}</h5>
                    </div>
                    <div id="online-devices" className="stat">
                      <h5>Devices Online: {devices["online_devices"]}</h5>
                    </div>
                    <div id="offline-devices" className="stat">
                      <h5>Devices Offline: {devices["offline_devices"]}</h5>
                    </div>
                  </div>
                  <div id="commanders" className="device-class">
                    <h4>Commanders</h4>
                    <DeviceList devices={devices["commanders"]} />
                  </div>
                  <div id="end-devices" className="device-class">
                    <h4>Field Sensors</h4>
                    <DeviceList devices={devices["end_devices"]} />
                  </div>
                </div>
              </Scrollbars>
            </>
          )
        }
      </div>
    </>
  )
}

export default Devices
