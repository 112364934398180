import React from "react"
import { hideErrors } from "../../services/validate.js"
import Loader from "react-loader-spinner";
import { postData } from "../../services/sendData.js"
import { Scrollbars } from 'react-custom-scrollbars';

import "../css/dashboard/createObject.scss"

class CreateFacility extends React.Component {
  collectValidateSubmitFacilityForm() {
    var hasErr = false;
    var name = document.getElementById('facility-name').value;
    var address = document.getElementById('facility-address').value;
    var city = document.getElementById('facility-city').value;
    var state = document.getElementById('facility-state').value;
    var country = document.getElementById('facility-country').value;
    var zip = document.getElementById('facility-zip').value;
    var type = document.getElementById('type').value;

    if(name.length === 0 || address.length === 0 || city.length === 0 || state.length === 0
        || country.length === 0 || zip.length === 0 || type === 'type') {
      hasErr = true;
      document.getElementById('form-nc').style.display = 'block';
      return;
    }

    if(!hasErr) {
      hideErrors();
      var data = {
        'name': name,
        'address': address,
        'city': city,
        'state': state,
        'country': country,
        'zip': zip,
        'type': type
      }
      var submit = document.getElementById('submit');
      submit.style.display = 'none';
      var load = document.getElementById('add-facility-load');
      load.style.display = 'inline';
      var response = postData("addUserFacility", data)
      var that = this;
      response.then(function(result) {
        if (result["status"] === 200) {
          that.props.hide(false)
          that.props.getter()
        } else {
          hasErr = true;
          document.getElementById('form-nc').style.display = 'block';
          var submit = document.getElementById('submit');
          submit.style.display = 'inline';
          var load = document.getElementById('add-facility-load');
          load.style.display = 'none';
          return;
        }
      })
    }
  }

  render () {
    return (
      <>
        <div id="new-facility" className="new-object">
          <div id="new-facility-content" className="new-object-content">
            <h5>New Facility</h5>
            <div id="new-object-form">
              <label>Facility Name</label><input type="text" id="facility-name" name="facility-name" required/>
              <label>Facility Address</label><input type="text" id="facility-address" name="facility-address" required/>
              <label>Facility City</label><input type="text" id="facility-city" name="facility-city" required/>
              <label>Facility State</label><input type="text" id="facility-state" name="facility-state" required/>
              <label>Facility Country</label><input type="text" id="facility-country" name="facility-country" required/>
              <label>Facility Zip</label><input type="text" id="facility-zip" name="facility-zip" required/>
              <label>Facility Type</label>
              <select id="type">
                <option value='type' disabled selected></option>
                <option value='indoor'>Indoor</option>
                <option value='outdoor'>Outdoor</option>
              </select>
            </div>
            <button id="submit" className="btn" onClick={() => this.collectValidateSubmitFacilityForm()}>
              Create
            </button>
            <button id="add-facility-load" className="btn load">
              <Loader type="Oval" color="#ffffff" height={20} width={20}/>
            </button>
            <button id="cancel-facility-form" className="btn" onClick={() => this.props.hide(false)}>
              Cancel
            </button>
            <h6 className="error" id="form-nc">Form not complete.</h6>
          </div>
        </div>
      </>
    )
  }
}

class CreateRoom extends React.Component {
  collectValidateSubmitRoomForm() {
    var hasErr = false;
    var name = document.getElementById('room-name').value;
    var racks = document.getElementById('room-racks').value;
    var rack_rows = document.getElementById('room-racks-rows').value;
    var rack_levels = document.getElementById('room-racks-levels').value;
    var tray_cols = document.getElementById('room-tray-cols').value;
    var tray_rows = document.getElementById('room-tray-rows').value;

    if(name.length === 0 || racks.length === 0 || rack_rows.length === 0 || rack_levels.length === 0
        || tray_cols.length === 0 || tray_rows.length === 0) {
      hasErr = true;
      document.getElementById('form-nc').style.display = 'block';
      return;
    }

    if (racks < 0 || rack_rows < 0 || rack_levels < 0 || tray_cols < 0 || tray_rows < 0) {
      hasErr = true;
      document.getElementById('form-invalid-num').style.display = 'block';
      return;
    }

    if(!hasErr) {
      hideErrors();
      var data = {
        'facility': this.props.facility,
        'name': name,
        'cols': racks,
        'rows': rack_rows,
        'levels': rack_levels,
        'tray_cols': tray_cols,
        'tray_rows': tray_rows
      }
      var submit = document.getElementById('submit');
      submit.style.display = 'none';
      var load = document.getElementById('add-room-load');
      load.style.display = 'inline';
      var response = postData("addUserRoom", data)
      var that = this;
      response.then(function(result) {
        if (result["status"] === 200) {
          that.props.hide(false)
          that.props.getter()
        } else {
          hasErr = true;
          document.getElementById('form-nc').style.display = 'block';
          var submit = document.getElementById('submit');
          submit.style.display = 'inline';
          var load = document.getElementById('add-room-load');
          load.style.display = 'none';
          return;
        }
      })
    }
  }

  render () {
    return (
      <>
        <div id="new-room" className="new-object">
          <div id="new-room-content" className="new-object-content">
            <h5>New Room</h5>
            <div id="new-object-form">
              <label>Room Name</label><input type="text" min='0' id="room-name" name="room-name" required/>
              <label>Number of Racks</label><input type="number" min='0' id="room-racks" name="room-racks" required/>
              <label>Number of Rows in Racks</label><input type="number" min='0' id="room-racks-rows" name="room-racks-rows" required/>
              <label>Number of Levels in Racks</label><input type="number" min='0' id="room-racks-levels" name="room-racks-levels" required/>
              <label>Number of Columns per Tray</label><input type="number" min='0' id="room-tray-cols" name="room-tray-cols" required/>
              <label>Number of Rows per Tray</label><input type="number" min='0' id="room-tray-rows" name="room-tray-rows" required/>
            </div>
            <button id="submit" className="btn" onClick={() => this.collectValidateSubmitRoomForm()}>
              Create
            </button>
            <button id="add-room-load" className="btn load">
              <Loader type="Oval" color="#ffffff" height={20} width={20}/>
            </button>
            <button id="cancel-room-form" className="btn" onClick={() => this.props.hide(false)}>
              Cancel
            </button>
            <h6 className="error" id="form-nc">Form not complete.</h6>
            <h6 className="error" id="form-invalid-num">Numbers must be greater than 0.</h6>
          </div>
        </div>
      </>
    )
  }
}

const CreateObject = ({object, hide, getter, facility}) => {
  return (
    <>
      {
        object === "facility" &&
        <CreateFacility hide={hide} getter={getter}/>
      }
      {
        object === "room" &&
        <CreateRoom hide={hide} getter={getter} facility={facility}/>
      }
    </>
  )
}

export default CreateObject
