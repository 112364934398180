import React, {useState, useEffect} from "react"
import Section from "./section.js"
import Environment from "./env.js"
import Lighting from "./lighting.js"
import Metadata from "./metadata.js"
import DataGraph from "../intelligence/dataGraph.js"

import "../css/dashboard/sectionView.scss"

const SectionView = ({trayRows, trayColumns, roomData, identifier, setter, setPlant, room, recipe}) => {
  const [showGraph, setShowGraph] = useState(false)
  const [metrics, setMetrics] = useState(['enviromentals', 'lighting'])
  const [currentMetric, setCurrentMetric] = useState('enviromentals')

  function backMetric() {
    var index = metrics.indexOf(currentMetric)
    if (index == 0) {
      index = metrics.length - 1;
    } else {
      index--;
    }
    setCurrentMetric(metrics[index])
  }

  function forwardMetric() {
    var index = metrics.indexOf(currentMetric)
    if (index == metrics.length - 1) {
      index = 0;
    } else {
      index++;
    }
    setCurrentMetric(metrics[index])
  }

  return (
    <>
      <div id="section-view-container">
        <div id="section-view" className="dash-overview-item">
          {
            showGraph &&
            <>
              <DataGraph scope="section" sensor="moisture" hideGraph={() => {setShowGraph(false)}} identifier={identifier} position="" room={room} rows={trayRows} cols={trayColumns}/>
            </>
          }
          {
            !showGraph &&
            <div id="section-view-wrapper">
              <h5>Tray</h5>
              <h6>Click plant to view more.</h6>
              <Section
                hideEnvData={true}
                rows={trayRows}
                cols={trayColumns}
                roomData={roomData}
                identifier={identifier}
                setter={setter}
                setPlant={setPlant}
                />
                <div className="view-moisture-data" onClick={() => setShowGraph(true)}>
                  {/*<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z"/>
                  </svg>*/}
                  <h6>View moisture over time.</h6>
                </div>
            </div>
          }
        </div>
        {
          currentMetric == "enviromentals" &&
          <div className="dash-overview-item" id="enviromentals">
            <Environment roomID={room} roomData={roomData} identifier={identifier} scope="section" back={backMetric} forward={forwardMetric} recipe={recipe}/>
          </div>
        }
        {
          currentMetric == "lighting" &&
          <div className="dash-overview-item" id="lighting">
            <Lighting roomID={room} roomData={roomData} identifier={identifier} scope="section" back={backMetric} forward={forwardMetric}/>
          </div>
        }
      </div>
      <Metadata scope="section" sectionID={identifier} roomID={room}/>
    </>
  )
}

export default SectionView
