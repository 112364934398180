import * as React from "react"
import {Link} from "gatsby"
import {navigate} from "gatsby"
import { logout } from "../../services/auth"
import MobileSidebar from "./mobileSidebar.js"

import "../css/utils/header.scss"

const Header = ({isLoggedIn, notifications}) => {
  return (
    <header id="header" className={isLoggedIn ? "bubble" : ""}>
      <div id="menu">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </div>
      { isLoggedIn &&
        <Link to="/portal/dashboard">
          <div id="brand-logo-hdr">
            <h1 id="brand-logo-name">InstaLeaf</h1>
          </div>
        </Link>
      }
      { !isLoggedIn &&
        <Link to="/">
          <div id="brand-logo-hdr">
            <h1 id="brand-logo-name">InstaLeaf</h1>
          </div>
        </Link>
      }

      <div id="auth-btns">
        {
          isLoggedIn &&
          <div>
            <div id="notification-bell" className="hdr-btn">
              {
                (notifications != null && notifications.num_notifications > 0) &&
                <div id="count"></div>
              }
              <Link to="/portal/notifications" state={{notifications: notifications}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16" id="bell">
                  <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"/>
                </svg>
              </Link>
            </div>
            <a
              href="/"
              onClick={event => {
                event.preventDefault()
                logout(() => navigate(`/login`))
              }}
            >
              <button className="btn hdr-btn" id="logout-btn">Log Out</button>
            </a>
          </div>
        }
        {
          !isLoggedIn &&
          <div>
            <Link to="/signup"><button className="btn" id="signup-btn">Sign Up</button></Link>
            <Link to="/login"><button className="btn" id="login-btn">Log In</button></Link>
          </div>
        }
      </div>
      <MobileSidebar />
    </header>
  );
}

export default Header
