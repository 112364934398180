import React, { useState, useEffect } from "react";
import { Router } from "@reach/router"
import Layout from "../components/utils/layout"
import Login from "../components/auth/login"
import Dashboard from "../components/dashboard/dashboard"
import Notifications from "../components/notifications/notifications"
import Schedule from "../components/schedule/schedule"
import NewGrow from "../components/schedule/newGrow"
import OptimizeGrows from "../components/schedule/optimizeGrows"
import GrowLogs from "../components/schedule/growLogs"
import Intelligence from "../components/intelligence/intelligence"
import Recipes from "../components/recipes/recipes"
import Org from "../components/org/org"
import Devices from "../components/devices/devices"
import AddDevice from "../components/devices/addDevice"
import Discover from "../components/discover/discover"
import Settings from "../components/settings/settings"
import PrivateRoute from "../components/utils/privateRoute"
import PortalLayout from "../components/utils/portalLayout"
import DataGraph from "../components/intelligence/dataGraph"
import DownloadApp from "./downloadApp"
import { getUser } from "../services/auth.js"

const App = () => {
  const [service, setService] = useState("")

  useEffect(() => {
    var user = getUser();
    setService(user['service']);
  }, [])

  if (service === "humidity-control") {
    return <DownloadApp />
  }

  return (
    <Layout>
      <PortalLayout>
        <Router>
          <PrivateRoute path="/portal/dashboard" component={Dashboard} />
          <PrivateRoute path="/portal/notifications" component={Notifications} />
          <PrivateRoute path="/portal/schedule" component={Schedule} />
          <PrivateRoute path="/portal/schedule/newGrow" component={NewGrow} />
          <PrivateRoute path="/portal/schedule/optimizeGrows" component={OptimizeGrows} />
          <PrivateRoute path="/portal/schedule/growLogs" component={GrowLogs} />
          <PrivateRoute path="/portal/intelligence" component={Intelligence} />
          <PrivateRoute path="/portal/intelligence/data" component={DataGraph} />
          <PrivateRoute path="/portal/recipes" component={Recipes} />
          <PrivateRoute path="/portal/org" component={Org} />
          <PrivateRoute path="/portal/devices" component={Devices} />
          <PrivateRoute path="/portal/devices/addDevice" component={AddDevice} />
          <PrivateRoute path="/portal/discover" component={Discover} />
          <PrivateRoute path="/portal/settings" component={Settings} />
        </Router>
      </PortalLayout>
    </Layout>
  )
}

export default App
