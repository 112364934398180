import React, { useState } from "react"
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { postData } from "../../services/sendData.js"
import { getUser } from "../../services/auth.js"
import YieldChart from "../dashboard/yieldChart.js"

import "../css/org/teamMember.scss"

class TeamMember extends React.Component {
  userType = getUser().userType;

  confirmDelete() {
    var header = document.getElementById('header');
    header.style.zIndex = -1;
    var siderbar = document.getElementById('sidebar');
    siderbar.style.zIndex = -1;
    var deleteUser = document.getElementById('delete-user');
    deleteUser.style.display = 'block';
  }

  hideDelete() {
    var header = document.getElementById('header');
    header.style.zIndex = 9999;
    var siderbar = document.getElementById('sidebar');
    siderbar.style.zIndex = 9999;
    var deleteUser = document.getElementById('delete-user');
    deleteUser.style.display = 'none';
  }

  handleDelete(that) {
    var data = {'id': that.props.member.ID}
    var response = postData('deleteOrgMember', data);
    response.then(function(result) {
      if (result["status"] === 200) {
        that.props.toHome()
      } else {
        document.getElementById('delete-error').style.display = 'block';
        return;
      }
    });
  }

  render() {
    return (
      <>
        <div id="member-overview">
          <div id="member-info-div">
            <h4>{this.props.member.FirstName} {this.props.member.LastName}</h4>
            <div>
              <h6 className="member-info">Email: {this.props.member.Email}</h6>
              <h6 className="member-info">Phone: {formatPhoneNumberIntl(this.props.member.Phone)}</h6>
              {this.props.member.TempPass != null && (this.userType == "owner") &&
                <h6>Security Code: {this.props.member.TempPass}</h6>
              }
              <h6>Privilege: {this.props.member.Privilege.charAt(0).toUpperCase() + this.props.member.Privilege.slice(1)}</h6>
            </div>
          </div>
          <div id="member-actions">
            <button className="changeMember btn" onClick={() => {this.props.editMember(true)}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
              </svg>
              Edit
            </button>
            <button className="changeMember btn delete-user-trigger-btn" onClick={this.confirmDelete}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
              </svg>
              Delete
            </button>
          </div>
        </div>
        <div id="delete-user">
          <div id="delete-user-content">
            <h5>Are you sure you want to delete this user?</h5>
            <div>
              <button className="btn delete-user-trigger-btn" onClick={() => this.handleDelete(this)}>Yes</button>
              <button className="btn" onClick={this.hideDelete}>No</button>
            </div>
            <h6 className="error" id="delete-error">Cannot delete user.</h6>
          </div>
        </div>
        <div id="yield-chart">
          <YieldChart scope="whole" user_id={this.props.member.ID} hideAddYield={true}/>
        </div>
      </>
    )
  }
}

export default TeamMember
