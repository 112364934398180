import React from "react"

import "../css/utils/back.scss"

class Back extends React.Component {
  render() {
    return (
      <>
        <button id="dash-back" className="btn" onClick={() => {this.props.click()}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
          </svg>
          <h6>Back</h6>
        </button>
      </>
    )
  }
}

export default Back;
