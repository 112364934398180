import React from "react"

import "../css/discover/discover.scss"

const Discover = () => {
  return (
    <>
      <div id="discover-content-div">
        <div id="up-to-date">
          <h5>Already up to date.</h5>
          <h6>Nothing new to discover.</h6>
        </div>
      </div>
    </>
  )
}

export default Discover
