import React, { useState, useEffect } from "react"
import Environment from "./env.js"
import Metadata from "./metadata.js"
import DataGraph from "../intelligence/dataGraph.js"

import "../css/dashboard/plantView.scss"

const PlantView = ({roomData, identifier, plant, room, recipe}) => {
  var sectionData = roomData()[identifier];
  const [showGraph, setShowGraph] = useState(false)

  function scale(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  function plantMoistureLevelCalc(identifier, plant) {
    if (sectionData != undefined) {
      var moisture_data = sectionData['moisture']['moisture']
      if (moisture_data[plant] != null) {
        if (moisture_data[plant] == 0) {
          return null
        }
        var plant_moisture = moisture_data[plant]
        var moisture_level = scale(plant_moisture, 3000, 1200, 0, 1)
        if (moisture_level > 1) {
          return 1
        }
        if (moisture_level < 0) {
          return 0
        }
        return moisture_level
      }
    }
    return 0
  }

  function plantMoistureLevel(identifier, plant) {
    var moisture_level = plantMoistureLevelCalc(identifier, plant)
    if (moisture_level == null) {
      return "0%"
    }
    return (moisture_level*100) + "%"
  }

  function plantStyle(identifier, plant) {
    var moisture_level = plantMoistureLevelCalc(identifier, plant)
    if (moisture_level == null) {
      return {
        backgroundColor: 'rgb(116, 185, 255, 0)',
      }
    }
    return {
      backgroundColor: 'rgb(116, 185, 255, ' + moisture_level.toString() + ')',
      height: plantMoistureLevel(identifier, plant),
    }
  }

  return (
    <>
      <div id="plant-view-container">
        <div id="plant-view" className="dash-overview-item">
          {
            showGraph &&
            <>
              <DataGraph scope="section" sensor="moisture" hideGraph={() => {setShowGraph(false)}} position="" identifier={identifier} room={room} plant={plant}/>
            </>
          }
          {
            !showGraph &&
            <div id="plant-view-wrapper">
                <h5>Plant</h5>
                <div id="plant-content">
                  <div id="plant" title={plantMoistureLevel(identifier, plant)} key={plant}>
                    <div className="moisture-level" style={plantStyle(identifier, plant)}>
                    </div>
                  </div>
                  <h6>Moisture Content: {Math.round(plantMoistureLevelCalc(identifier, plant)*100 * 10) / 10}%</h6>
                  <div className="view-moisture-data" onClick={() => setShowGraph(true)}>
                    <h6>View moisture over time.</h6>
                  </div>
                </div>
              </div>
            }
        </div>
        <div className="dash-overview-item" id="enviromentals">
          <Environment roomID={room} roomData={roomData} identifier={identifier} scope="plant" recipe={recipe}/>
        </div>
      </div>
      <Metadata scope="plant" plantID={plant} sectionID={identifier} roomID={room}/>
    </>
  )
}

export default PlantView
