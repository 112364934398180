import React, { useState, useEffect } from "react"
import { getData } from "../../services/sendData.js"
import CurrentRecipes from "./currentRecipes.js"
import AddRecipe from "./addRecipe.js"
import Loading from "../utils/loading.js"
import { Scrollbars } from 'react-custom-scrollbars';

import "../css/recipes/recipe.scss"

const Recipes = () => {
  const [addRecipe, setAddRecipe] = useState(false)
  const [recipes, setRecipes] = useState(null)
  const [loading, setLoading] = useState(false)

  async function getRecipe() {
    var data = getData('getGrowRecipes')
    await data.then(function(result) {
      if (result['status'] == 200) {
        setRecipes(result['data'])
        setLoading(false)
       }
    });
  }

  useEffect(() => {
    setLoading(true)
    getRecipe()
  }, [])

  return (
    <>
      {
        loading || recipes == null ? (
          <>
            <Loading />
          </>
        ) : (
          <div id="dash-content">
            <Scrollbars autoHide>
              {
                !addRecipe && recipes != null &&
                <CurrentRecipes setRecipe={setAddRecipe} recipes={recipes}/>
              }
              {
                addRecipe &&
                <AddRecipe setRecipe={setAddRecipe} getRecipe={getRecipe}/>
              }
            </Scrollbars>
          </div>
        )
      }
    </>
  )
}

export default Recipes
