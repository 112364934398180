import React, { useState, useEffect } from "react"
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { getData } from "../../services/sendData.js"
import Loading from "../utils/loading.js"
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import dates from 'react-big-calendar/lib/utils/dates';
import moment from 'moment'

import "../css/schedule/schedule.scss"
import "react-big-calendar/lib/css/react-big-calendar.css"

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar);

// Watch out for start and end time zone
// TODO: ONVIEW CONTENT CHANGE
const Schedule = () => {
  const [dateRange, setDateRange] = useState({});
  const [growList, setGrowList] = useState([]);
  const [view, setView] = useState("month");

  async function getUserGrows(){
    if (dateRange != {} || dateRange != undefined) {
      var getUrl = 'getUserGrows?'
      if (view == "month") {
        getUrl += 'type=schedule&'
      } else {
        getUrl += 'type=events&'
      }
      getUrl += 'start=' + dateRange['startDay']
      getUrl += '&end=' + dateRange['endDay']
      var data = getData(getUrl)
      await data.then(function(result) {
        if (result['status'] == 200 && result['data'] != null) {
          for(var i = 0; i < result["data"].length; i++) {
            result['data'][i]["start"] = moment(result['data'][i]["start"]).local().toDate();
            result['data'][i]["end"] = moment(result['data'][i]["end"]).local().toDate();
          }
          setGrowList(result['data'] != null ? result["data"] : [])
         }
      });
    }
  }

  useEffect(() => {
    onNavigate(new Date(), "month")
  }, [])

  useEffect(() => {
    getUserGrows()
  }, [dateRange])

  useEffect(() => {
    getUserGrows()
  }, [view])

  const onNavigate = (date, view) => {
    var range = {
      'startDay': moment(date).startOf('month').startOf('week').startOf('day').toJSON(),
      'endDay': moment(date).endOf('month').endOf('week').endOf('day').toJSON(),
    }
    setDateRange(range);
  }

  return (
    <>
      <div id="dash-content">
        <DnDCalendar
          defaultDate={moment().toDate()}
          onNavigate = {onNavigate}
          defaultView="month"
          view={view}
          onView={setView}
          events={growList}
          localizer={localizer}
          resizable
          style={{ height: "100vh" }}
        />
      </div>
    </>
  )
}

export default Schedule
