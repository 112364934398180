import React, { useState, useEffect } from "react"
import { getData } from "../../services/sendData.js"
import { getUser } from "../../services/auth.js"
import PropTypes from "prop-types"
import Sidebar from "./sidebar.js"
import Header from "./header.js"
import AdminDashboard from "../admin/admin.js"

import "../css/utils/portalLayout.scss"

var userType = getUser().userType;

const PortalLayout = ({ children }) => {
  const [notifications, setNotifications] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  
  async function getUserNotifications(){
    var data = getData('getUserNotifications');
    await data.then(function(result) {
      if (result['status'] == 200) {
        setNotifications(result['data'])
       }
    });
  }

  useEffect(() => {
    getUserNotifications()
  }, [])

  if (userType === "admin") {
    return (
      <div id="main-app">
        <Header isLoggedIn={true} notifications={notifications} />
        <div id="pl-main">
          <AdminDashboard />
        </div>
      </div>
    )
  }

  return (
    <>
      <div id="main-app">
        <Header isLoggedIn={true} notifications={notifications} />
        <div id="pl-main">
          <Sidebar />
          <div id="pl-content">
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

PortalLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PortalLayout
