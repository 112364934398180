import React, { useState, useEffect } from "react"
import { getData, postData } from "../../services/sendData.js"
import Loading from "../utils/loading.js"
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from "react-loader-spinner";
import { navigate } from "gatsby"
import Select from 'react-select'

import "../css/devices/addDevices.scss"

var positions = [
  {"label": "N", "value": "N"},
  {"label": "S", "value": "S"},
  {"label": "W", "value": "W"},
  {"label": "E", "value": "E"}
];

const AddDevice = () => {
  const [page, setPage] = useState(0);
  const [device, setDevice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [facilities, setFacilities] = useState(null);
  const [facility, setFacility] = useState(null);
  const [roomFull, setRoomFull] = useState(null);
  const [rooms, setRooms] = useState(null);
  const [room, setRoom] = useState(null);
  const [rows, setRows] = useState(null);
  const [row, setRow] = useState(null);
  const [columns, setColumns] = useState(null);
  const [column, setColumn] = useState(null);
  const [levels, setLevels] = useState(null);
  const [level, setLevel] = useState(null);
  const [position, setPosition] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  async function getUserFacilities(){
    var data = getData('getUserFacilities');
    await data.then(function(result) {
      if (result['status'] == 200) {
        var facilities_list = [];
        for(var i = 0; i < result['data'].length; i++) {
          facilities_list.push({
            'label': result['data'][i].Name,
            'value': result['data'][i].ID
          })
        }
        setFacilities(facilities_list)
       }
    });
  }

  async function getUserRooms(index){
    var data = getData('getUserRooms');
    await data.then(function(result) {
      if (result['status'] == 200) {
        var room_list = [];
        for(var i = 0; i < result['data'].length; i++) {
          room_list.push({
            'label': result['data'][i].Name,
            'value': result['data'][i].ID
          })
        }
        setRoomFull(result["data"]);
        setRooms(room_list);
       }
    });
  }

  async function checkID(id){
    var data = getData('checkBoardID', 'id=' + id);
    await data.then(function(result) {
      if (result['status'] == 200) {
        if (result['data'] != null) {
          getUserFacilities();
          getUserRooms();
          setDevice(result['data']);
          setLoading(false);
          setPage(page+1);
          setError(false);
        } else {
          setLoading(false);
          setError(true);
        }
      }
    });
  }

  function next() {
    if (page === 0) {
      var id = document.getElementById("boardID").value;
      if (id.length != 0) {
        setError(false);
        setLoading(true);
        setSuccess(false);
        checkID(id)
      } else {
        setError(true);
      }
    }
  }

  function reset() {
    setPage(0);
    setDevice(null);
    setLoading(false);
    setFacility(null);
    setError(false);
    setPosition(null);
  }

  function submit() {
    var data = {};
    var hasErr = false;
    if (device.Type === "commander") {
      if (facility === null) {
        setError(true);
        hasErr = true;
      } else {
        data = {
          "board": device.Board,
          "facility": facility
        }
      }
    } else if (device.Type.split("-")[0] === "si") {
      if (room === null) {
        setError(true);
        hasErr = true;
      } else {
        data = {
          "board": device.Board,
          "room": room,
          "row": row,
          "column": column,
          "level": level,
          "position": position
        }
      }
    }
    if(!hasErr) {
      setError(false);
      setLoading(true);
      var response = postData("addDevice", data);
      response.then(function(result) {
        if (result["status"] === 200) {
          setSuccess(true);
          reset();
          setLoading(false);
        } else {
          setLoading(false);
          setError(true);
        }
      })
    }
  }

  function updateLocation(r) {
    var room_dict = {};
    for(var i = 0; i < roomFull.length; i++) {
      if (roomFull[i].ID == r) {
        Object.assign(room_dict, roomFull[i]);
        break;
      }
    }
    var row_list = [];
    for(var i = 1; i < room_dict.Rows+1; i++) {
      row_list.push({"label": i, "value": i});
    }
    setRows(row_list);
    var col_list = [];
    for(var i = 1; i < room_dict.Columns+1; i++) {
      col_list.push({"label": i, "value": i});
    }
    setColumns(col_list);
    var level_list = [];
    for(var i = 1; i < room_dict.Levels+1; i++) {
      level_list.push({"label": i, "value": i});
    }
    setLevels(level_list);
  }

  function handleRowChange(f) {
    setRow(f.value);
  }

  function handleColumnChange(f) {
    setColumn(f.value);
  }

  function handleLevelChange(f) {
    setLevel(f.value);
  }

  function handleFacilityChange(f) {
    setFacility(f.value);
  }

  function handleRoomChange(r) {
    updateLocation(r.value);
    setRoom(r.value);
  }

  function handlePositionsChange(p) {
    setPosition(p.value);
  }

  return (
    <>
    <div className="add-device-container">
      <div className="add-device-content-wrapper">
        <div className="add-device-content">
          <div className="add-device-content-header">
            <h3>Add Device</h3>
          </div>
          <div className="add-device-form">
            {
              page === 0 &&
              <>
                <h6>Please enter the ID of your device. The ID is usually located on the back of the device.</h6>
                <input type="text" name="boardID" id="boardID" placeholder="Board ID" required/>
              </>
            }
            {
              page === 1 &&
              <>
                {
                  device.Type === "commander" &&
                  <>
                    <h6>Adding a commander device. Please select what facility to assign it to.</h6>
                    <Select options={facilities} onChange={handleFacilityChange} />
                  </>
                }
                {
                  device.Type.split("-")[0] === "si" &&
                  <>
                    <h6>Adding a SI device. Please select what room to assign it to.</h6>
                    <Select options={rooms} onChange={handleRoomChange} />
                    {
                      room != null &&
                      <>
                        <h6>Select location. (Optional)</h6>
                        <Select options={rows} onChange={handleRowChange} placeholder="Select row..."/>
                        <Select options={columns} onChange={handleColumnChange} placeholder="Select column..."/>
                        <Select options={levels} onChange={handleLevelChange} placeholder="Select level..."/>
                        {
                          ["si-thc", "si-rgb"].includes(device.Type) &&
                          <>
                            <h6>For more than one sensor per section (finer data granularity), please choose section location. (Optional)</h6>
                            <Select options={positions} onChange={handlePositionsChange} placeholder="Select position..."/>
                          </>
                        }
                      </>
                    }
                  </>
                }
              </>
            }
          </div>
          <div id="add-devices-btns">
            {
              !loading &&
              <>
                {
                  page === 0 &&
                  <>
                    <button id="next" className="btn" onClick={() => next()}>
                      Next
                    </button>
                  </>
                }
                {
                  page === 1 &&
                  <>
                    <button id="submit" className="btn" onClick={() => submit()}>
                      Add
                    </button>
                  </>
                }
              </>
            }
            {
              loading &&
              <>
                <button id="add-devices-load" className="btn">
                  <Loader type="Oval" color="#ffffff" height={17} width={17}/>
                </button>
              </>
            }
            <button id="add-devices-cancel" className="btn" onClick={() => navigate("/portal/devices")}>
              Cancel
            </button>
            {
              error &&
              <h6 className="error" id="form-nc">Form not complete.</h6>
            }
            {
              success && !error &&
              <h6 className="success" id="success">Device successfully added.</h6>
            }
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default AddDevice
