import React from "react"
import { Scrollbars } from 'react-custom-scrollbars';
import Back from "../utils/back.js"

import "../css/dashboard/envConsole.scss"

const EnvConsole = ({clickBackToRoom, temp, hum, co2}) => {
  var clone_vpd_bounds = [0.4, 0.9]
  var vegetative_vpd_bounds = [0.9, 1.2]
  var bloom_vpd_bounds = [1.2, 1.6]
  var vpdCurr = (Math.round(vpd(temp, temp, hum) * 100) / 100).toFixed(2);

  function fToC(temp) {
    var c = (temp-32)*(5/9);
    var roundedC = Math.round(c * 10) / 10;
    return roundedC;
  }

  function vPSat(temp) {
    return 0.61078*Math.exp((17.27*temp)/(temp+237.3))
  }

  function vpd(temp, leaf_temp, humidity) {
    temp = fToC(temp);
    leaf_temp = fToC(leaf_temp) - 0.5;
    var vPSat_air = vPSat(temp)
    var vPSat_leaf = vPSat(leaf_temp)
    var vpd = vPSat_leaf - (vPSat_air*(humidity/100))
    return vpd
  }

  function current(vpd) {
    if(vpd === vpdCurr) {
      return " current";
    }
    return "";
  }

  function vpdColor(vpd) {
    var roundedVpd = (Math.round(vpd * 100) / 100).toFixed(2);
    if (vpd < clone_vpd_bounds[0] || vpd > bloom_vpd_bounds[1]) {
      return <td className={"mold-dry" + current(roundedVpd)}>{roundedVpd}</td>
    } else if (vpd > clone_vpd_bounds[0] && vpd < clone_vpd_bounds[1]) {
      return <td className={"clone" + current(roundedVpd)}>{roundedVpd}</td>
    } else if (vpd > vegetative_vpd_bounds[0] && vpd < vegetative_vpd_bounds[1]) {
      return <td className={"vegetative" + current(roundedVpd)}>{roundedVpd}</td>
    } else if (vpd > bloom_vpd_bounds[0] && vpd < bloom_vpd_bounds[1]) {
      return <td className={"bloom" + current(roundedVpd)}>{roundedVpd}</td>
    }
  }

  function printVPDRow(temp) {
    var elements = [];
    for(var i = 100; i > 0; i -= 5) {
      if(temp === 0) {
        if(i === 100) {
          elements.push(<td className="hdr">Temperature (&deg;F)</td>);
        } else {
          elements.push(<td className="hdr">{i}</td>);
        }
      } else {
        if(i === 100) {
          elements.push(<td className="hdr">{temp}</td>);
        } else {
          elements.push(vpdColor(vpd(temp, temp, i)));
        }
      }
    }
    return elements;
  }

  function printVPD() {
    var rows = [];
    for(var i = 0; i < 100; i += 5) {
      rows.push(<tr>{printVPDRow(i)}</tr>);
    }
    return rows;
  }

  return (
    <div className="env-console">
      <div className="env-console-hdr">
        <Back click={clickBackToRoom}/>
        <h5>Enviromental Console</h5>
      </div>
      <div className="legend">
        <h5>VPD Legend</h5>
        <h6 className="current">Current Value: {vpdCurr}</h6>
        <h6 className="mold-dry">Mold/Dry: &lt; {clone_vpd_bounds[0]} or &gt; {clone_vpd_bounds[1]}</h6>
        <h6 className="clone">Clone: {clone_vpd_bounds[0]}-{clone_vpd_bounds[1]}</h6>
        <h6 className="vegetative">Vegetative: {vegetative_vpd_bounds[0]}-{vegetative_vpd_bounds[1]}</h6>
        <h6 className="bloom">Bloom: {bloom_vpd_bounds[0]}-{bloom_vpd_bounds[1]}</h6>
      </div>
      <table>
        <tr>
          <th></th>
          <th className="hdr" colspan="100">Humidity (%)</th>
        </tr>
        {printVPD()}
      </table>
    </div>
  )
}

export default EnvConsole;
