import React, { useState, useEffect } from "react"
import { getData, postData } from "../../services/sendData.js"
import Loader from "react-loader-spinner";
import { Scrollbars } from 'react-custom-scrollbars';
import { format } from 'date-fns'


import "../css/dashboard/metadata.scss"

const Log = ({data}) => {
  const [showContent, setShowContent] = useState(false)

  return (
    <div className="log">
      <div className="log-hdr">
        <div className="pointer" onClick={() => setShowContent(!showContent)}>
          {
            !showContent ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
            </svg> :
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          }
        </div>
        <h6>{format(new Date(data.CreatedAt), "MM/dd/yyyy hh:mm:ss a")} &#8212; {data.Name} ({data.Identifier})</h6>
      </div>
      {
        showContent &&
        <div className="log-content">
          <h6>{JSON.stringify(data.Data)}</h6>
        </div>
      }
    </div>
  )
}

const Logs = ({scope, sectionID, roomID, plantID}) => {
  const [logs, setLogs] = useState([])
  const [loading, setLoading] = useState(false)

  async function getLogData(offset=0){
    var getUrl = "getLogData?offset=" + offset.toString();
    if (scope === "section" || scope === "plant") {
      getUrl += "&section=" + sectionID
    }
    if (scope === "plant") {
      getUrl += "&plant=" + plantID
    }
    if (roomID != null) {
      getUrl += "&room=" + roomID
    }
    var data = getData(getUrl);
    await data.then(function(result) {
      if (result['status'] == 200) {
        setLogs(logs.concat(result['data']))
        setLoading(false)
       }
    });
  }

  function loadMoreData() {
    setLoading(true)
    var offset = logs.length
    getLogData(offset)
  }

  useEffect(() => {
    setLoading(true)
    getLogData()
  }, [])

  if (loading) {
    return (
      <div id="loading-logs">
        <Loader type="Oval" color="#2ecc71" height={15} width={15}/>
        <h6>Loading logs</h6>
      </div>
    )
  }

  if (logs.length === 0) {
    return (
      <h6>No logs available.</h6>
    )
  }

  return (
    <>
      {
        logs.map((data, i) => (
          <>
            <Log data={data} />
          </>
        ))
      }
      <h6 id="load-more" onClick={() => loadMoreData()}>Load more...</h6>
    </>
  )
}

const Metadata = ({scope, sectionID, roomID, plantID}) => {
  const [content, setContent] = useState("general")
  const [general, setGeneral] = useState(null);
  const [loading, setLoading] = useState(false);
  const [measurements, setMeasurements] = useState([{ item: "", value: "" }]);
  const [metrics, setMetrics] = useState([]);
  const [hasErr, setHasErr] = useState(false);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...measurements];
    list[index][name] = value;
    setMeasurements(list);
  };

  const handleRemoveClick = index => {
    const list = [...measurements];
    list.splice(index, 1);
    setMeasurements(list);
  };

  const handleAddClick = () => {
    setMeasurements([...measurements, { item: "", value: "" }]);
  };

  const reset = () => {
    setMeasurements([{ item: "", value: "" }]);
  };

  async function addMetrics() {
    console.log("adding metric");
    var validMetrics = true;
    for(var i = 0; i < measurements.length; i++) {
      if (measurements[i].item == "" || measurements[i].value == "") {
        validMetrics = false;
      }
    }

    if(validMetrics) {
      setHasErr(false);
      setLoading(true);
      var data = {
        measurements: measurements,
        roomID: roomID,
        identifier: sectionID,
        plantID: plantID+1
      }
      var response = postData("addMetrics", data);
      response.then(function(result) {
        if (result["status"] === 200) {
          reset();
          setLoading(false);
        } else {
          setLoading(false);
          setHasErr(true);
        }
      })
    } else {
      setHasErr(true);
    }
  }

  async function getGeneralData(){
    var getUrl = "getGeneralData?room=" + roomID;
    var data = getData(getUrl);
    await data.then(function(result) {
      if (result['status'] == 200) {
        setGeneral(result["data"])
        setLoading(false)
       }
    });
  }

  async function getMeasurementMetrics() {
    var getUrl = "getMeasurementMetrics";
    var data = getData(getUrl);
    await data.then(function(result) {
      if (result['status'] == 200) {
        setMetrics(result["data"])
      }
    });
  }

  useEffect(() => {
    setLoading(true)
    getGeneralData()
    getMeasurementMetrics()
  }, [])

  return (
    <>
      <div id="metadata-container">
        <div id="metadata-content-wrapper">
          <div id="metadata-content">
            <div id="metadata-header">
              <ul>
                <li className={content === "general" ? "active": null} onClick={() => setContent("general")}>General</li>
                <li className={content === "automation" ? "active": null} onClick={() => setContent("automation")}>Automation</li>
                <li className={content === "measurements" ? "active": null} onClick={() => setContent("measurements")}>Measurements</li>
                <li className={content === "logs" ? "active": null} onClick={() => setContent("logs")}>Logs</li>
              </ul>
            </div>
            <div id="metadata-body">
              <Scrollbars autoHide>
                {
                  content === "general" &&
                  <>
                    {
                      loading &&
                      <div id="loading-general">
                        <Loader type="Oval" color="#2ecc71" height={30} width={30}/>
                        <h6>Loading grow data...</h6>
                      </div>
                    }
                    {
                      !loading &&
                      <>
                        <div id="general-content">
                          <h6><b>Grow Name:</b> {general != null && general.name.length != 0 ? general.name : "--"}</h6>
                          <h6><b>Status:</b> {general != null && general.status.length != 0 ? general.status.charAt(0).toUpperCase() + general.status.slice(1) : "--"}</h6>
                          <h6><b>Recipe:</b> {general != null && general.recipe.length != 0 ? general.recipe : "--"}</h6>
                          <h6><b>Day:</b> {general != null && general.day.length != 0 ? general.day : "--"}</h6>
                          <h6><b>Supervisior(s):</b> {general == null || general.supervisors == null || general.supervisors.length == 0 ? "--" : ""}</h6>
                          {
                            general == null || general.supervisors == null || general.supervisors.length == 0 ? "" :
                            <>
                              <ul>
                                {general.supervisors.map(s => (<li key={s}>{s}</li>))}
                              </ul>
                            </>
                          }
                          <h6><b>Operator(s):</b> {general == null || general.operators == null || general.operators.length == 0 ? "--" : ""}</h6>
                          {
                            general == null || general.operators == null || general.operators.length == 0 ? "" :
                            <>
                              <ul>
                                {general.operators.map(o => (<li key={o}>{o}</li>))}
                              </ul>
                            </>
                          }
                        </div>
                      </>
                    }
                  </>
                }
                {
                  content === "automation" &&
                  <>
                    <div id="automation-content">
                      <h6>No automation devices registered.</h6>
                    </div>
                  </>
                }
                {
                  content === "measurements" &&
                  <>
                    <div id="measurements-content">
                      <h6>Enter custom measurement metric:</h6>
                      {measurements.map((x, i) => {
                        return (
                          <>
                            <div className="measurement-label-value">
                              <input type="text" className="measurement-input" list="metrics" placeholder="Label" name="item" onChange={e => handleInputChange(e, i)} value={x.item}></input>
                              <input type="text" className="measurement-value" placeholder="Value" name="value" onChange={e => handleInputChange(e, i)} value={x.value}></input>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16" onClick={() => handleRemoveClick(i)}>
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                              </svg>
                            </div>
                          </>
                        )
                      })}
                      <datalist id="metrics">
                        {
                          metrics != null &&
                          metrics.map((x, i) => {
                            return (
                              <>
                                <option value={x}></option>
                              </>
                            )
                          })
                        }
                      </datalist>
                      <div className="add-metric" onClick={() => handleAddClick()}>
                        <h6>+ Add Metric</h6>
                      </div>
                      <div id="add-measurements-btn">
                        {
                          !loading &&
                          <>
                            <button id="submit" className="btn" onClick={() => addMetrics()}>
                              Add
                            </button>
                          </>
                        }
                        {
                          loading &&
                          <>
                            <button id="add-measurements-load" className="btn">
                              <Loader type="Oval" color="#ffffff" height={17} width={17}/>
                            </button>
                          </>
                        }
                      </div>
                      {
                        hasErr &&
                        <>
                          <h6 className="error">Metrics form incomplete.</h6>
                        </>
                      }
                    </div>
                  </>
                }
                {
                  content === "logs" &&
                  <>
                    <div id="logs-content">
                      <Logs scope={scope} sectionID={sectionID} roomID={roomID} plantID={plantID}/>
                    </div>
                  </>
                }
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Metadata;
