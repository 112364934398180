import React, { useState, useEffect } from "react"
import { getData } from "../../services/sendData.js"
import AddMember from './addMember.js'
import Loading from "../utils/loading.js"
import Team from './team.js'
import TeamMember from './teamMember.js'
import Back from '../utils/back.js'
import { Scrollbars } from 'react-custom-scrollbars';

const Org = () => {
  const [org, setOrg] = useState(null)
  const [member, setMember] = useState(null)
  const [addMember, setAddMember] = useState(false)
  const [loading, setLoading] = useState(false)

  async function getUserOrg(){
    var data = getData('getUserOrg')
    await data.then(function(result) {
      if (result['status'] == 200) {
        setOrg(result['data'])
        setLoading(false)
       }
    });
  }

  useEffect(() => {
    setLoading(true)
    getUserOrg()
  }, [])

  function clickBackToHome() {
    setMember(null)
  }

  function clickBackToHomeRefresh() {
    clickBackToHome()
    getUserOrg()
  }

  return (
    <>
      {
        loading || org == null ? (
          <>
            <Loading />
          </>
        ) : (
        <div id="dash-content">
          <Scrollbars autoHide>
            {
              member != null && !addMember &&
              <>
                <div id="back-btn">
                  <Back click={clickBackToHome}/>
                </div>
                <TeamMember member={org[member]} memberIndex={member} toHome={clickBackToHomeRefresh} editMember={setAddMember}/>
              </>
            }
            {
              member == null && !addMember &&
              <>
                <Team team={org} clickMember={setMember} addMember={setAddMember}/>
              </>
            }
            {
              addMember && member == null &&
              <AddMember getOrg={getUserOrg} setMember={setMember} addMember={setAddMember} member={null}/>
            }
            {
              addMember && member != null &&
              <AddMember getOrg={getUserOrg} setMember={setMember} addMember={setAddMember} member={org[member]}/>
            }
          </Scrollbars>
        </div>
        )
      }
    </>
  )
}

export default Org
