import React, {useState, useEffect} from "react"
import { getData } from "../../services/sendData.js"
import {Link} from "gatsby"
import Loader from "react-loader-spinner";
import moment from 'moment'
import { Scrollbars } from 'react-custom-scrollbars';

import "../css/schedule/growLogs.scss"

const Log = ({log}) => {
  const [hide, setHide] = useState(true);

  return (
    <>
      <tr className="log" onClick={() => setHide(!hide)} style={{backgroundColor: !hide ? "#f5f6fa" : ""}}>
        <td>
          <div className="arrows">
            {
              hide ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                  <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              )
            }
          </div>
          <div className="name">{log.Name}</div>
        </td>
        <td className="start">{moment(log.StartTime).format('MM/DD/YYYY hh:mm A')}</td>
        <td className="end">{moment(log.EndTime).format('MM/DD/YYYY hh:mm A')}</td>
        <td className="active">
          <button className={`btn status-btn ${log.Status + "-btn"}`}>{log.Status.charAt(0).toUpperCase() + log.Status.slice(1)}</button>
        </td>
      </tr>
      {
        hide ? (<></>) : (
          <tr className="log-preview">
            <td colspan="4">
              <div className="log-preview-content">
                <div className="log-preview-details">
                  <h5>{log.Name}</h5>
                  <h6>Created By: {log.Creator.FirstName + " " + log.Creator.LastName}</h6>
                  <h6>Facility: {log.Room.Facility.Name}</h6>
                  <h6>Room: {log.Room.Name}</h6>
                  <h6>Recipe: {log.Recipe.Name}</h6>
                </div>
                <div>
                </div>
              </div>
            </td>
          </tr>
        )
      }
    </>
  )
}

const GrowLogs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getUserGrows(){
    var data = getData('getUserGrows');
    await data.then(function(result) {
      if (result['status'] == 200) {
        setLogs(result['data'])
        setLoading(false)
       }
    });
  }

  useEffect(() => {
    setLoading(true);
    getUserGrows();
  }, [])

  return (
    <>
      <div id="dash-content">
        <div id="grow-logs-div">
          <h3>Grow Logs</h3>
          <div id="grow-logs-content">
            <Scrollbars autoHide>
              {
                loading &&
                <>
                  <div className="content-loader">
                    <div>
                      <Loader type="Oval" color="#2ecc71" height={30} width={30}/>
                      <h6>Loading logs...</h6>
                    </div>
                  </div>
                </>
              }
              {
                logs.length === 0 && !loading &&
                <>
                  <div id="no-logs">
                    <h5>No grows completed.</h5>
                    <Link to="/portal/schedule/newGrow"><button className="btn">+ Create a New Grow</button></Link>
                  </div>
                </>
              }
              {
                logs.length != 0 && !loading &&
                <>
                   <table id="grow-table">
                     <tr id="grow-table-header">
                       <th>Name</th>
                       <th>Start</th>
                       <th>End</th>
                       <th>Status</th>
                     </tr>
                     {
                       Array.from(logs, (e, i) => {
                          return (
                            <Log log={e} />
                          )
                        })
                      }
                   </table>
                </>
              }
            </Scrollbars>
          </div>
        </div>
      </div>
    </>
  )
}

export default GrowLogs;
