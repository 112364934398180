import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { postData } from "../../services/sendData.js"
import moment from 'moment'

const Week = (props) => {
  const processNuts = (nuts) => {
    var nutData = [];
    for(var i = 0; i < nuts.length; i++) {
      nutData.push({ item: nuts[i], value: "0" });
    }
    return nutData;
  }

  const [inputList, setInputList] = useState(props.nuts.length === 0 ? [{ item: "", value: "" }] : processNuts(props.nuts));

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { item: "", value: "" }]);
  };

  return (
    <>
      <tr>
        <div>
          <h5>Week {props.num}</h5>
          <div className="week-values">
            <div className="constants">
              <h6>Constants:</h6>
              <div className="week-value enviroment-value">
                <h6>Watering Time:</h6>
                <input type="number" name="water-low" placeholder="0" required/>
                <h6>seconds to </h6>
                <input type="number" name="water-high" placeholder="0" required/>
                <h6>seconds</h6>
              </div>
              <div className="week-value enviroment-value">
                <h6>CO<sub>2</sub>:</h6>
                <input type="number" name="co2" placeholder="0" required/>
                <h6>PPM</h6>
              </div>
              <div className="week-value enviroment-value">
                <h6>End of Week Flush</h6>
                <input type="checkbox" name="flush" />
              </div>
            </div>
            <div className="nutrients">
              <h6>Nutrients:</h6>
              {
                inputList.map((x, i) => {
                  return (
                    <div className="week-value">
                      <input type="type" name="item" placeholder="Nutrient" className={props.stage + "-nut"} value={x.item} onChange={e => handleInputChange(e, i)} required/>
                      <input type="number" name="value" placeholder="0" min="0" value={x.value} onChange={e => handleInputChange(e, i)} required/>
                      <h6>gallons</h6>
                      <button className="add-item" onClick={handleAddClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                        </svg>
                      </button>
                      {
                        inputList.length !== 1 &&
                        <button className="remove-item" onClick={() => handleRemoveClick(i)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
                          </svg>
                        </button>
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </tr>
    </>
  )
}

const Stage = (props) => {
  const [week, setWeek] = useState(1)
  const [toggle, setToggle] = useState(true);
  const [nuts, setNuts] = useState([]);

  function getNuts() {
    const collection = document.getElementsByClassName(props.name + "-nut");
    for(var i = 0; i < collection.length; i++) {
      var nutVal = collection[i].value;
      var hasNut = false;
      for(var y = 0; y < nuts.length; y++) {
        if(nuts[y] == nutVal) {
          hasNut = true;
        }
      }
      if(!hasNut) {
        nuts.push(nutVal);
        setNuts(nuts);
      }
    }
  }

  function removeNuts() {
    const collection = document.getElementsByClassName(props.name + "-nut");
    for(var i = 0; i < collection.length; i++) {
      var numOcc = 0;
      for(var z = 0; z < collection.length; z++) {
        if(collection[i] == collection[z]) {
          numOcc++;
        }
      }
      if(numOcc === 1) {
        var index = nuts.indexOf(collection[i]);
        nuts.splice(index, 1);
        setNuts(nuts);
      }
    }
  }

  function addWeek() {
    setWeek(week+1)
    getNuts()
  }

  function removeWeek() {
    if(week > 1) {
      setWeek(week-1)
      removeNuts()
    }
  }

  return (
    <>
      <div className="stage" id={props.name}>
        <div className="stage-header">
          <h4>{props.name.charAt(0).toUpperCase() + props.name.slice(1)}</h4>
          {/*<div className="toggle">
            {
              toggle &&
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            }
            {
              !toggle &&
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
              </svg>
            }
          </div>*/}
        </div>
        {
          toggle &&
          <>
            <div className="stage-data">
              <div className="globals">
                <h5>Globals</h5>
                <div className="global-value">
                  <label><h6>Mix Tank: </h6></label>
                  <input type="number" name="tank" placeholder="0" required/>
                  <h6>gallons</h6>
                </div>
                <div className="global-value">
                  <label><h6>PH: </h6></label>
                  <input type="number" name="ph-low" placeholder="0" required/>
                  <h6>to</h6>
                  <input type="number" name="ph-high" placeholder="0" required/>
                </div>
                <div className="global-value">
                  <label><h6>Temperature: </h6></label>
                  <input type="number" name="temp-low" placeholder="0" required/>
                  <h6>&deg; F to</h6>
                  <input type="number" name="temp-high" placeholder="0" required/>
                  <h6>&deg; F</h6>
                </div>
                <div className="global-value">
                  <label><h6>Humidity: </h6></label>
                  <input type="number" name="hum-low" placeholder="0" required/>
                  <h6>% to</h6>
                  <input type="number" name="hum-high" placeholder="0" required/>
                  <h6>%</h6>
                </div>
                <div className="global-value">
                  <label><h6>Lighting: On from</h6></label>
                  <input type="time" name="on-start" placeholder="00:00" required/>
                  <h6>to</h6>
                  <input type="time" name="on-end" placeholder="12:00" required/>
                  <h6>ramp</h6>
                  <input type="number" name="on-ramp" placeholder="0" required/>
                  <h6>% every</h6>
                  <input type="number" name="on-ramp-period" placeholder="0" required/>
                  <h6>hour.</h6>
                </div>
                <div className="global-value">
                  <label><h6>Lighting: Off from</h6></label>
                  <input type="time" name="off-start" placeholder="00:00" required/>
                  <h6>to</h6>
                  <input type="time" name="off-end" placeholder="12:00" required/>
                  <h6>ramp</h6>
                  <input type="number" name="off-ramp" placeholder="0" required/>
                  <h6>% every</h6>
                  <input type="number" name="off-ramp-period" placeholder="0" required/>
                  <h6>hour.</h6>
                </div>
              </div>
              <table className="weeks">
                { [...Array(week)].map((_, i) => <Week key={i} num={i+1} stage={props.name} nuts={nuts}/>) }
              </table>
            </div>
            <div className="week-btn-div">
              <button className="add-week week-btn" onClick={() => {addWeek()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                </svg>
                Add Week
              </button>
              <button className="remove-week week-btn" onClick={() => {removeWeek()}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
                </svg>
                Remove Week
              </button>
            </div>
          </>
        }
      </div>
    </>
  )
}

const AddRecipe = (props) => {
  function collectFormData() {
    var data = {}

    var recipe_name = document.getElementById("recipe-name").value;
    data['name'] = recipe_name;
    data['globals'] = {};
    data['weeks'] = [];

    var stages = document.getElementsByClassName('stage');
    for(var i = 0; i < stages.length; i++) {
      var stage = stages[i];
      var name = stage.id;
      var stage_data = {};

      var globals = {};
      var globalHtml = stage.getElementsByClassName('stage-data')[0].getElementsByClassName('globals')[0].getElementsByClassName('global-value');
      for(var k = 0; k < globalHtml.length; k++) {
        var inputs = globalHtml[k].getElementsByTagName('input');
        for (var z = 0; z < inputs.length; z++) {
          if(inputs[z].name == "on-start" || inputs[z].name == "off-start" || inputs[z].name == "on-end" || inputs[z].name == "off-end") {
            var local = moment(inputs[z].value, 'HH:mm').utc().format("HH:mm");
            globals[inputs[z].name] = local;

          } else {
            globals[inputs[z].name] = inputs[z].value;
          }
        }
      }
      data['globals'][name] = globals;

      var weeks = stage.getElementsByClassName('stage-data')[0].getElementsByClassName('weeks')[0].getElementsByTagName('tr');
      for(var k = 0; k < weeks.length; k++){
        var week = {};
        var constant_values = weeks[k].getElementsByClassName("constants")[0].getElementsByClassName("week-value");
        for(var z = 0; z < constant_values.length; z++){
          var inputs = constant_values[z].getElementsByTagName('input');
          for (var l = 0; l < inputs.length; l++) {
            if (inputs[l].type && inputs[l].type === 'checkbox') {
              week[inputs[l].name] = inputs[l].checked;
            } else {
              week[inputs[l].name] = inputs[l].value;
            }
          }
        }
        var nutrients = weeks[k].getElementsByClassName("nutrients")[0].getElementsByClassName("week-value");
        var nutrient_list = [];
        for(var z = 0; z < nutrients.length; z++){
          var inputs = nutrients[z].getElementsByTagName('input');
          var nutrients_data = [inputs[0].value, inputs[1].value];
          nutrient_list.push(nutrients_data);
        }
        week['nutrients'] = nutrient_list;
        week['stage'] = name;
        data['weeks'].push(week);
      }
    }
    return data;
  }

  function validateFormData(data) {
    for (const [key, value] of Object.entries(data)) {
      if (key === "globals") {
        for (const [k, v] of Object.entries(value)) {
          for (const [x, y] of Object.entries(v)) {
            if (v === "") {
              return true;
            }
          }
        }
      } else if (key === "weeks") {
        for(var i = 0; i < value.length; i++) {
          var week = value[i];
          for (const [k, v] of Object.entries(week)) {
            if (k != "nutrients" && v === "") {
              return true;
            }
          }
          for(var j = 0; j < week['nutrients']; j++) {
            var pair = week[j];
            if (pair[0] === "" || pair[1] === "") {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  function collectAndValidateFormData() {
    var data = collectFormData();
    // console.log(data);
    var hasErr = validateFormData(data);

    if (hasErr) {
      document.getElementById("form-nc").style.display = "inline";
    } else {
      document.getElementById("form-nc").style.display = "none";
      document.getElementById("add-recipe-send").style.display = "none";
      document.getElementById("add-recipe-load").style.display = "inline";
      var response = postData("addGrowRecipe", data)
      response.then(function(result) {
        if (result["status"] === 200) {
          props.getRecipe()
          props.setRecipe(false)
        } else {
          hasErr = true;
          document.getElementById('form-nc').style.display = 'inline';
          document.getElementById("add-recipe-send").style.display = "inline";
          document.getElementById("add-recipe-load").style.display = "none";
          return;
        }
      })
    }
  }

  return (
    <>
      <div id="add-recipe-div">
        <h3>Add Recipe</h3>
        <input type="text" name="name" placeholder="Recipe Name" id="recipe-name" required />
        <div id="add-recipe-form">
          {/*<Stage name="clone"/>*/}
          <Stage name="vegetative"/>
          <Stage name="bloom"/>
        </div>
        <button id="add-recipe-send" className="btn submit-btn" onClick={() => { collectAndValidateFormData(); }}>
          Create
        </button>
        <button id="add-recipe-load" className="btn submit-btn">
          <Loader type="Oval" color="#ffffff" height={20} width={20}/>
        </button>
        <button id="add-recipe-cancel" className="btn submit-btn" onClick={() => { props.setRecipe(false); }}>
          Cancel
        </button>
        <h6 className="error" id="form-nc">Form is not complete.</h6>
      </div>
    </>
  )
}

export default AddRecipe
