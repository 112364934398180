import React, { useState, useEffect } from "react"
import { getData, postData } from "../../services/sendData.js"
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { validate_email, hideErrors } from "../../services/validate.js"
import Loading from "../utils/loading.js"
import Loader from "react-loader-spinner";
import { Scrollbars } from 'react-custom-scrollbars';

import "../css/settings/settings.scss"

const Settings = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [phone_number, set_phone_number] = useState(user != null ? user.Phone : "");

  async function getUser(){
    var data = getData('getUserData')
    await data.then(function(result) {
      if (result['status'] == 200) {
        setUser(result['data'])
        set_phone_number(result['data'].Phone)
        setLoading(false)
       }
    });
  }

  useEffect(() => {
    setLoading(true)
    getUser()
  }, [])

  async function collectAndValidateFormData() {
    var hasErr = false;
    var first_name = document.getElementById('first-name').value;
    var last_name = document.getElementById('last-name').value;
    var email = document.getElementById('email').value;
    var phone = phone_number;
    if(first_name.length === 0 || last_name.length === 0 || email.length === 0 || phone === undefined) {
      hasErr = true;
      document.getElementById('form-nc').style.display = 'block';
      return;
    }
    if(!validate_email(email)) {
      hasErr = true;
      document.getElementById('invalid-email').style.display = 'block';
      return;
    }
    if(!isValidPhoneNumber(phone)) {
      hasErr = true;
      document.getElementById('invalid-pn').style.display = 'block';
      return;
    }

    if(!hasErr) {
      hideErrors();
      var data = {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'phone_number': phone_number,
      }

      var submit = document.getElementById('submit');
      submit.style.display = 'none';
      var load = document.getElementById('load');
      load.style.display = 'inline';
      await new Promise(r => setTimeout(r, 200));
      var response;
      response = postData("updateUserData", data)
      response.then(function(result) {
        if (result["status"] === 200) {
          getUser()
        } else {
          hasErr = true;
          document.getElementById('form-nc').style.display = 'block';
        }
      })
      var submit = document.getElementById('submit');
      submit.style.display = 'inline';
      var load = document.getElementById('load');
      load.style.display = 'none';
    }
  }

  return (
    <>
      {
        loading && user == null &&
        <Loading />
      }
      {
        !loading && user != null &&
        <>
          <div id="dash-content">
            <Scrollbars autoHide>
              <div id="settings">
                <h3>Settings</h3>
                <div id="general">
                  <h5 className="hdr">General</h5>
                  <div class="general-form">

                    <label>First Name</label>
                    <input type="text" id="first-name" name="first-name" defaultValue={user.FirstName} required/>

                    <label>Last Name</label>
                    <input type="text" id="last-name" name="last-name" defaultValue={user.LastName} required/>

                    <label>Email</label>
                    <input type="email" id="email" name="email" defaultValue={user.Email} required/>

                    <label>Phone Number</label>
                    <PhoneInput
                      id="phone_number"
                      placeholder="Phone Number"
                      defaultCountry="US"
                      value={phone_number}
                      onChange={set_phone_number} required/>
                  </div>
                </div>
                <button id="submit" className="btn" onClick={() => { collectAndValidateFormData(); }}>
                  Save
                </button>
                <button id="load" className="btn">
                  <Loader type="Oval" color="#ffffff" height={20} width={20}/>
                </button>
                <h6 className="error" id="form-nc">Form not complete.</h6>
                <h6 className="error" id="invalid-email">Invalid email.</h6>
                <h6 className="error" id="invalid-pn">Invalid phone number.</h6>
              </div>
            </Scrollbars>
          </div>
        </>
      }
    </>
  )
}

export default Settings
