import React, { useState } from "react";
import {Link} from "gatsby"

import "../components/css/downloadApp.scss"

const DownloadApp = () => (
  <>
    <div className="download-app-container">
      <div className="download-app-wrapper">
        <Link to="/">
          <div id="brand-logo-hdr">
            <h1 id="brand-logo-name">InstaLeaf</h1>
          </div>
        </Link>
        <div className="info">
          <h3>Please download our app to utilize our services!</h3>
          <div className="buttons">
            <Link to="https://apps.apple.com/ca/app/instaleaf-smart-agriculture/id1635453189">
              <h5>Download on the Apple App Store</h5>
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=ai.instaleaf.app&hl=en&gl=US">
              <h5>Download on the Google Play Store</h5>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default DownloadApp
