import React, {useState, useEffect} from "react"
import DataGraph from "../intelligence/dataGraph.js"
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment'

import "../css/dashboard/env.scss"

//TODO: LOCATIONAL BASED ALGORITHM TO CALCULATE ENVIROMENTALS BASED OFF PLANT LOCATION TO SENSOR
const EnvItem = ({scope, sectionData, click, expected}) => {
  function cToF(temp) {
    var c = (temp*9/5)+32;
    var roundedC = Math.round(c * 10) / 10;
    return roundedC;
  }

  const diff_min = sectionData != undefined ? moment.duration(moment().diff(moment(sectionData['created_at']).local())).asMinutes() : null

  return (
    <>
      <li className="env-item" onClick={() => sectionData != undefined ? click(scope, sectionData["position"]) : undefined}>
        <div className="data">
          <h6 className="title">
            {scope != "co2" ? scope.charAt(0).toUpperCase() + scope.slice(1) : <>CO<sub>2</sub></>}
          </h6>
          <h3>
            {
              sectionData === undefined || diff_min > 15 ?
              "-- " : [
                scope === "temperature" ?
                " " + cToF(sectionData[scope]) :
                " " + Math.round(sectionData[scope] * 10)/10
              ]
            }
            {scope === "temperature" && <>&deg;F</>}
            {scope === "humidity" && "%"}
            {scope === "co2" && "PPM"}
          </h3>
          <div className="more-info">
            <h6>
              Expected:
              {
                expected === undefined || expected == null ?
                " -- " : " " + expected
              }
              {scope === "temperature" && <>&deg;F</>}
              {scope === "humidity" && "%"}
              {scope === "co2" && "PPM"}
            </h6>
            <h6>{sectionData != undefined && sectionData["position"] != "" ? "Position: " + sectionData["position"] : ""}</h6>
          </div>
        </div>
      </li>
    </>
  )
}

const Environment = ({roomID, roomData, identifier, scope, back, forward, recipe}) => {
  const [showGraph, setShowGraph] = useState(false)
  const [sensor, setSensor] = useState(null)
  const [position, setPosition] = useState("")

  function dataClick(sensorType, position) {
    setSensor(sensorType);
    setPosition(position);
    setShowGraph(true);
  }

  function grabRecipeValue(metric) {
    if (recipe == null || recipe["Recipe"] == null) {
      return null
    }
    var start = moment(recipe["StartTime"]).local();
    var end = moment(recipe["EndTime"]).local();
    var today = moment(new Date());

    if (moment(today).isBefore(end, 'day') && moment(start).isBefore(today, 'day')) {
      var number_of_weeks_from_start = moment.duration(today.diff(start));
      var diff_days = number_of_weeks_from_start.asDays();
      var num_weeks = Math.floor(diff_days/7)
      var week_data = recipe["Recipe"]["GrowCycle"]["weeks"][num_weeks];
      var stage = week_data["stage"];
      var globals = recipe["Recipe"]["GrowCycle"]["globals"][stage];
      if (metric == "temperature") {
        return globals['temp-low'] + "-" + globals['temp-high']
      } else if (metric == "humidity") {
        return globals['hum-low'] + "-" + globals['hum-high']
      } else if (metric == "co2") {
        return week_data['co2']
      }
    } else {
      return null
    }
  }

  return (
    <>
      <div id="env-container">
        {
          showGraph &&
          <>
            <DataGraph scope={scope} sensor={sensor} hideGraph={() => {setShowGraph(false)}} identifier={identifier} position={position} room={roomID}/>
          </>
        }
        {
          !showGraph &&
          <>
            <div id="env-content-wrapper">
              <div id="env-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16" onClick={() => back()}>
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
                <h5>Environment</h5>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16" onClick={() => forward()}>
                  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
                    <div id="env-list-container">
                    <Scrollbars>

                      {
                        (roomData() === undefined ||
                        roomData()[identifier] === undefined ||
                        roomData()[identifier]["enviromentals"] === undefined) &&
                        <>
                          <ul id="env-list">
                            <EnvItem scope="temperature" click={dataClick}/>
                            <EnvItem scope="humidity" click={dataClick}/>
                            <EnvItem scope="co2" click={dataClick}/>
                          </ul>
                        </>
                      }
                      {
                        roomData()[identifier] != undefined &&
                        roomData()[identifier]["enviromentals"].map((x, i) => {
                          return (
                            <>
                              <ul id="env-list">
                                <EnvItem scope="temperature" sectionData={x} expected={grabRecipeValue("temperature")} click={dataClick}/>
                                <EnvItem scope="humidity" sectionData={x} expected={grabRecipeValue("humidity")} click={dataClick}/>
                                <EnvItem scope="co2" sectionData={x} expected={grabRecipeValue("co2")} click={dataClick}/>
                              </ul>
                            </>
                          )
                        })
                      }
                      </Scrollbars>
                  </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default Environment
