import React from "react"
import moment from "moment";

import "../css/dashboard/room.scss"

class Section extends React.Component {
  sectionData = this.props.roomData() === undefined || this.props.roomData()[this.props.identifier] === undefined ? undefined : this.props.roomData()[this.props.identifier];
  clone_vpd_bounds = [0.4, 0.9]
  vegetative_vpd_bounds = [0.9, 1.2]
  bloom_vpd_bounds = [1.2, 1.6]

  scale(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  plantMoistureLevelCalc(identifier, plant) {
    if (this.sectionData !== undefined && this.sectionData['moisture']['moisture'] !== null) {
      var moisture_data = this.sectionData['moisture']['moisture']
      if (moisture_data[plant] != null) {
        if (moisture_data[plant] === 0) {
          return null
        }
        var plant_moisture = moisture_data[plant]
        var moisture_level = this.scale(plant_moisture, 3000, 1200, 0, 1)
        if (moisture_level > 1) {
          return 1
        }
        if (moisture_level < 0) {
          return 0
        }
        return moisture_level
      }
    }
    return 0
  }

  plantMoistureLevel(identifier, plant) {
    var moisture_level = this.plantMoistureLevelCalc(identifier, plant)
    if (moisture_level == null) {
      return "0%"
    }
    return (moisture_level*100) + "%"
  }

  plantStyle(identifier, plant) {
    var moisture_level = this.plantMoistureLevelCalc(identifier, plant)
    if (moisture_level == null) {
      return {
        backgroundColor: 'rgb(116, 185, 255, 0)',
      }
    }
    return {
      backgroundColor: 'rgb(116, 185, 255, ' + moisture_level.toString() + ')',
      height: this.plantMoistureLevel(identifier, plant),
    }
  }

  getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }

  cToF(temp) {
    var f = (temp*9/5)+32;
    var roundedF = Math.round(f * 10) / 10;
    return roundedF;
  }

  fToC(temp) {
    var c = (temp-32)*(5/9);
    var roundedC = Math.round(c * 10) / 10;
    return roundedC;
  }

  printEnviromentals(values, position) {
    return (
      <div className={position === "R" || position === "L" ? "enviromentals enviromentals-inline" : "enviromentals"} onClick={() => this.props.envConsole(this.cToF(values['temperature']), values['humidity'], values['co2'])}>
        <h6>T: {this.sectionData === undefined || values['temperature'] === undefined || moment.duration(moment().diff(moment(values['created_at']).local())).asMinutes() > 15 ? "--" : this.cToF(values['temperature'])}&deg;F</h6>
        <h6>H: {this.sectionData === undefined || values['humidity'] === undefined || moment.duration(moment().diff(moment(values['created_at']).local())).asMinutes() > 15 ? "--" : Math.round(values['humidity'] * 10) / 10}%</h6>
        <h6>CO<sub>2</sub>: {this.sectionData === undefined || values['co2'] === undefined || moment.duration(moment().diff(moment(values['created_at']).local())).asMinutes() > 15 ? "--" : Math.round(values['co2'])} PPM</h6>
      </div>
    )
  }

  findLightingValues(value) {
    if (this.sectionData === undefined) {
      return undefined
    }
    if (this.sectionData['lightings'] === null) {
      return undefined
    }
    var avg = this.calculateMetricAverage(this.sectionData['lightings'], value);
    if (value == "r" || value == "b" || value == "g") {
      return avg/256;
    }
    return avg;
  }

  calculateMetricAverage(list, metric, position=null) {
    var amount = 0;
    var sum = 0;
    for (var m = 0; m < list.length; m++) {
      var diff_min = moment.duration(moment().diff(moment(list[m]['created_at']).local())).asMinutes()
      if (diff_min < 15) {
        if (position == null) {
          sum += list[m][metric];
          amount++;
        } else {
          if (list[m]["position"] == position) {
            sum += list[m][metric];
            amount++;
          }
        }
      }
    }

    if (metric == "temperature") {
      return this.cToF(sum/amount);
    }

    return sum/amount;
  }

  //TODO: MAKE THIS WEIGHTED BY DISTANCE FROM EXPECTED
  metricFromAvgGrad(metric, avg, recipeValue, pos) {
    var value = avg;
    var opacity = 100;
    if (recipeValue != null) {
      if(recipeValue.length == 1) {
        value = recipeValue[0];
        opacity = Math.floor(Math.round((1 - Math.min(metric, value)/Math.max(metric, value))*100));
      } else {
        // console.log(metric);
        // console.log(recipeValue);
        if (metric >= recipeValue[0] && metric <= recipeValue[1]) {
          value = metric;
        } else {
          var closest = recipeValue.reduce(function(prev, curr) {
            return (Math.abs(curr - metric) < Math.abs(prev - metric) ? curr : prev);
          });
          value = closest;
          opacity = Math.floor(Math.round((1 - Math.min(metric, value)/Math.max(metric, value))*100));
        }
      }
    }

    if (metric > value) {
      return 'linear-gradient(' + pos + ', rgba(231, 76, 60,' + opacity.toString() + '%), rgba(255, 255, 255, 0) 50%)'
    } else if (metric < value) {
      return 'linear-gradient(' + pos + ', rgba(52, 152, 219,' + opacity.toString() + '%), rgba(255, 255, 255, 0) 50%)'
    } else {
      return 'linear-gradient(' + pos + ', rgba(255, 255, 255, 100%), rgba(255, 255, 255, 0) 50%)'
    }
  }

  airflowModel(metric) {
    if (this.sectionData === undefined || this.sectionData['enviromentals'] === null) {
      return {}
    }
    var avg, n, w, e, s;
    if (metric == "all-air") {
      avg = (this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature") +
            this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity") +
            this.calculateMetricAverage(this.sectionData['enviromentals'], "co2"))/3
      n = (this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "N") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "N") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "co2", "N"))/3
      s = (this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "S") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "S") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "co2", "S"))/3
      w = (this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "W") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "W") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "co2", "W"))/3
      e = (this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "E") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "E") +
          this.calculateMetricAverage(this.sectionData['enviromentals'], "co2", "E"))/3
    } else {
      avg = this.calculateMetricAverage(this.sectionData['enviromentals'], metric)
      n = this.calculateMetricAverage(this.sectionData['enviromentals'], metric, "N")
      s = this.calculateMetricAverage(this.sectionData['enviromentals'], metric, "S")
      w = this.calculateMetricAverage(this.sectionData['enviromentals'], metric, "W")
      e = this.calculateMetricAverage(this.sectionData['enviromentals'], metric, "E")
    }

    var nGrad = this.metricFromAvgGrad(n, avg, this.props.recipeValue, 'to bottom')
    var sGrad = this.metricFromAvgGrad(s, avg, this.props.recipeValue, 'to top')
    var wGrad = this.metricFromAvgGrad(w, avg, this.props.recipeValue, 'to right')
    var eGrad = this.metricFromAvgGrad(e, avg, this.props.recipeValue, 'to left')

    return {
      background: nGrad + ',' + sGrad + ',' + wGrad + ',' + eGrad
    }
  }

  vPSat(temp) {
    return 0.61078*Math.exp((17.27*temp)/(temp+237.3))
  }

  vpd(temp, leaf_temp, humidity) {
    temp = this.fToC(temp);
    leaf_temp = this.fToC(leaf_temp) - 0.5;
    var vPSat_air = this.vPSat(temp)
    var vPSat_leaf = this.vPSat(leaf_temp)
    var vpd = vPSat_leaf - (vPSat_air*(humidity/100))
    return vpd
  }

  vpdGrad(vpd, position) {
    var stage = this.props.stage;

    if(vpd > 1.6) {
      return 'linear-gradient(' + position + ', rgba(231, 76, 60, 100%), rgba(255, 255, 255, 0) 50%)'
    } else if(vpd < 0.6) {
      return 'linear-gradient(' + position + ', rgba(52, 152, 219, 100%), rgba(255, 255, 255, 0) 50%)'
    }

    if(stage === "vegetative") {
      if(vpd > this.vegetative_vpd_bounds[1]) {
        var opacity = Math.floor(Math.round((1 - Math.min(vpd, this.vegetative_vpd_bounds[1])/Math.max(vpd, this.vegetative_vpd_bounds[1]))*100));
        return 'linear-gradient(' + position + ', rgba(231, 76, 60,' + opacity.toString() + '%), rgba(255, 255, 255, 0) 50%)'
      } else if(vpd < this.vegetative_vpd_bounds[0]) {
        var opacity = Math.floor(Math.round((1 - Math.min(vpd, this.vegetative_vpd_bounds[0])/Math.max(vpd, this.vegetative_vpd_bounds[0]))*100));
        return 'linear-gradient(' + position + ', rgba(52, 152, 219,' + opacity.toString() + '%), rgba(255, 255, 255, 0) 50%)'
      } else {
        return 'linear-gradient(' + position + ', rgba(255, 255, 255, 100%), rgba(255, 255, 255, 0) 50%)'
      }
    } else if(stage === "bloom") {
      if(vpd > this.bloom_vpd_bounds[1]) {
        var opacity = Math.floor(Math.round((1 - Math.min(vpd, this.bloom_vpd_bounds[1])/Math.max(vpd, this.bloom_vpd_bounds[1]))*100));
        return 'linear-gradient(' + position + ', rgba(231, 76, 60,' + opacity.toString() + '%), rgba(255, 255, 255, 0) 50%)'
      } else if(vpd < this.bloom_vpd_bounds[0]) {
        var opacity = Math.floor(Math.round((1 - Math.min(vpd, this.bloom_vpd_bounds[0])/Math.max(vpd, this.bloom_vpd_bounds[0]))*100));
        return 'linear-gradient(' + position + ', rgba(52, 152, 219,' + opacity.toString() + '%), rgba(255, 255, 255, 0) 50%)'
      } else {
        return 'linear-gradient(' + position + ', rgba(255, 255, 255, 100%), rgba(255, 255, 255, 0) 50%)'
      }
    }
  }

  vpdModel() {
    if (this.sectionData === undefined || this.sectionData['enviromentals'] === null) {
      return {}
    }

    var n = this.vpd(this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "N"),
            this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "N"),
            this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "N"))
    var s = this.vpd(this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "S"),
            this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "S") - 0.5,
            this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "S"))
    var w = this.vpd(this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "W"),
            this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "W") - 0.5,
            this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "W"))
    var e = this.vpd(this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "E"),
            this.calculateMetricAverage(this.sectionData['enviromentals'], "temperature", "E") - 0.5,
            this.calculateMetricAverage(this.sectionData['enviromentals'], "humidity", "E"))

    var nGrad = this.vpdGrad(n, 'to bottom')
    var sGrad = this.vpdGrad(s, 'to top')
    var wGrad = this.vpdGrad(w, 'to right')
    var eGrad = this.vpdGrad(e, 'to left')

    return {
      background: nGrad + ',' + sGrad + ',' + wGrad + ',' + eGrad
    }
  }

  filterStyle(filter) {
    if (filter == "blue"){
      var value = this.findLightingValues("b")
      var blue = value != undefined ? value : 0
      return {
        backgroundColor: 'rgba(0, 0,' + blue.toString() + ', 0.7)'
      }
    } else if (filter == "green") {
      var value = this.findLightingValues("g")
      var green = value != undefined ? value : 0
      return {
        backgroundColor: 'rgba(0,' + green.toString() + ', 0, 0.7)'
      }
    } else if (filter == "red") {
      var value = this.findLightingValues("r")
      var red = value != undefined ? value : 0
      return {
        backgroundColor: 'rgba(' + red.toString() + ', 0, 0, 0.7)'
      }
    } else if (filter == "color") {
      var redV = this.findLightingValues("r")
      var greenV = this.findLightingValues("g")
      var blueV = this.findLightingValues("b")
      var red = redV != undefined ? redV : 0
      var green = greenV != undefined ? greenV : 0
      var blue = blueV != undefined ? blueV : 0
      return {
        backgroundColor: 'rgba(' + red.toString() + ', ' + green.toString() + ', ' + blue.toString() + ', 0.7)'
      }
    } else if (filter == "lux") {
      var luxV = this.findLightingValues("lux")
      var lux = luxV != undefined ? luxV : 0
      return {
        backgroundColor: 'rgba(255, 234, 167, ' + this.scale(lux, 0, 50000, 0, 1).toString() + ')'
      }
    } else if (filter == "all-air" || filter == "temperature" || filter == "humidity" || filter == "co2") {
      return this.airflowModel(filter)
    } else if (filter == "vpd") {
      return this.vpdModel()
    }
  }

  render () {
    return (
      <>
        <div className="section-view">
          {
            this.props.filter != null &&
            <div className="filter" style={this.filterStyle(this.props.filter)}></div>
          }
          {
            !this.props.hideEnvData &&
            this.sectionData != undefined &&
            this.sectionData["enviromentals"] != null &&
            this.sectionData["enviromentals"].map((x, i) => {
              if(x["position"] === "" || x["position"] === "N") {
                return this.printEnviromentals(x, x["position"])
              }
            })
          }
          <div className="env-section">
            {
              !this.props.hideEnvData &&
              this.sectionData != undefined &&
              this.sectionData["enviromentals"] != null &&
              this.sectionData["enviromentals"].map((x, i) => {
                if(x["position"] === "L") {
                  return this.printEnviromentals(x, x["position"])
                }
              })
            }
            <div className="section">
              {
                Array.from(Array(this.props.rows+1), (e, i) => {
                  return (
                    <>
                      <div className="trayRows" key={i}>
                        {
                          i == 0 &&
                          Array.from(Array(this.props.cols+1), (a, y) => {
                            return (
                              <>
                                <div className="trayColumns tray-row-header">
                                  {
                                    y != 0 && <h6>{y}</h6>
                                  }
                                </div>
                              </>
                            )
                          })
                        }
                        {
                          i != 0 &&
                          <div className="trayColumns tray-col-header">
                            <h6>{i}</h6>
                          </div>
                        }
                        {
                          i != 0 &&
                          Array.from(Array(this.props.cols), (a, y) => {
                            return (
                              <>
                                <div title={this.plantMoistureLevel(this.props.identifier, y+((i-1)*this.props.cols))} className="trayColumns" key={y+((i-1)*this.props.cols)} onClick={ this.props.setPlant != null ? () => this.props.setPlant(y+((i-1)*this.props.cols)) : null}>
                                  <div className="moisture-level" style={this.plantStyle(this.props.identifier, y+((i-1)*this.props.cols))}></div>
                                </div>
                              </>
                            )
                          })
                        }
                      </div>
                    </>
                  )
                })
              }
            </div>
            {
              !this.props.hideEnvData &&
              this.sectionData != undefined &&
              this.sectionData["enviromentals"] != null &&
              this.sectionData["enviromentals"].map((x, i) => {
                if(x["position"] === "R") {
                  return this.printEnviromentals(x, x["position"])
                }
              })
            }
          </div>
          {
            !this.props.hideEnvData &&
            this.sectionData != undefined &&
            this.sectionData["enviromentals"] != null &&
            this.sectionData["enviromentals"].map((x, i) => {
              if(x["position"] === "S") {
                return (
                  this.printEnviromentals(x, x["position"])
                )
              }
            })
          }
          {
            this.props.setter != null &&
            <button onClick={() => {this.props.setter(this.props.identifier)}}><h6>View More</h6></button>
          }
        </div>
      </>
    )
  }
}

export default Section
