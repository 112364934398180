import React from "react"
import { getUser } from "../../services/auth.js"

import "../css/dashboard/facilities.scss"

class Facilities extends React.Component {
  userType = getUser().userType;

  render() {
    return (
      <>
        <div id="facilities">
          <div className="facility-hdr">
            <h5>Facilities</h5>
            {
              (this.userType == "owner") ?
              <div className="add-facility" onClick={() => this.props.addObject(true)}>
                <h6>Create Facility +</h6>
              </div> :
              <>
              </>
            }
          </div>
          <div id="facility-list">
            {
              this.props.facilities.length < 1 &&
              <div id="no-facilities">
                <h6>You have no facilities.</h6>
              </div>
            }
            {
              this.props.facilities.length > 0 &&
              this.props.facilities.map((value, index) => {
                return (
                  <div className="facility" key={index} onClick={() => {this.props.setter(index)}}>
                    <h6>{value.Name}</h6>
                    {/*<h6 className="facility-status">Health: Stable  <span class="dot"></span></h6>*/}
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }
}

export default Facilities
