import React, {useState, useEffect} from "react"

import "../css/schedule/optimizeGrows.scss"

const OptimizeGrows = () => {
  return (
    <>
      <div id="dash-content">
        <div id="optimize-grows-div">
          <h3>Optimize Grows</h3>
        </div>
      </div>
    </>
  )
}

export default OptimizeGrows;
