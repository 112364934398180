import React, { useState } from "react"

import "../css/dashboard/filters.scss"

const Filters = ({filter, setFilter}) => {
  const [lighting, setLighting] = useState(false)
  const [airflow, setAirflow] = useState(false)
  const [lux, setLux] = useState(false)
  const [color, setColor] = useState(false)
  const [r, setR] = useState(false)
  const [g, setG] = useState(false)
  const [b, setB] = useState(false)
  const [temp, setTemp] = useState(false)
  const [hum, setHum] = useState(false)
  const [co2, setCO2] = useState(false)
  const [allAir, setAllAir] = useState(false)
  const [vpd, setVpd] = useState(false)

  function setMainCheck(main, value) {
    uncheckAll()
    setLighting(false)
    setAirflow(false)
    setFilter(null)
    if (main == setAirflow && !value) {
      setAllAir(true)
      setFilter("all-air")
    }
    main(!value)
  }

  function uncheckAll() {
    setLux(false)
    setColor(false)
    setR(false)
    setG(false)
    setB(false)
    setTemp(false)
    setHum(false)
    setCO2(false)
    setAllAir(false)
    setVpd(false)
  }

  function setFilterCheck(main, value, filterType) {
    uncheckAll()
    if (value){
      setFilter(null)
    } else {
      setFilter(filterType)
    }
    main(!value)
  }

  return (
    <div className="filters">
      <div className="filter-title">
        {/*<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
        </svg>*/}
        <h6>Filters:</h6>
      </div>
      <div className="filter-main">
        <div className="filter-item">
          <h6>Lighting</h6>
          <label class="switch">
            <input type="checkbox" checked={lighting} onChange={() => setMainCheck(setLighting, lighting)}/>
            <span class="slider round"></span>
          </label>
        </div>
        <div className="filter-item">
          <h6>Airflow</h6>
          <label class="switch">
            <input type="checkbox" checked={airflow} onChange={() => setMainCheck(setAirflow, airflow)}/>
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      {
        airflow &&
        <div className="filter-air filter-sub">
          <div className="filter-item">
            <h6>All</h6>
            <label class="switch">
              <input type="checkbox" checked={allAir} onChange={() => setFilterCheck(setAllAir, allAir, "all-air")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>Temperature</h6>
            <label class="switch">
              <input type="checkbox" checked={temp} onChange={() => setFilterCheck(setTemp, temp, "temperature")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>Humidity</h6>
            <label class="switch">
              <input type="checkbox" checked={hum} onChange={() => setFilterCheck(setHum, hum, "humidity")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>CO<sub>2</sub></h6>
            <label class="switch">
              <input type="checkbox" checked={co2} onChange={() => setFilterCheck(setCO2, co2, "co2")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>Vapor Pressure Deficit</h6>
            <label class="switch">
              <input type="checkbox" checked={vpd} onChange={() => setFilterCheck(setVpd, vpd, "vpd")}/>
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      }
      {
        lighting &&
        <div className="filter-lighting filter-sub">
          <div className="filter-item" checked={lux}>
            <h6>Lux</h6>
            <label class="switch">
              <input type="checkbox" checked={lux} onChange={() => setFilterCheck(setLux, lux, "lux")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>Color</h6>
            <label class="switch">
              <input type="checkbox" checked={color} onChange={() => setFilterCheck(setColor, color, "color")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>Red</h6>
            <label class="switch">
              <input type="checkbox" checked={r} onChange={() => setFilterCheck(setR, r, "red")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>Green</h6>
            <label class="switch">
              <input type="checkbox" checked={g} onChange={() => setFilterCheck(setG, g, "green")}/>
              <span class="slider round"></span>
            </label>
          </div>
          <div className="filter-item">
            <h6>Blue</h6>
            <label class="switch">
              <input type="checkbox" checked={b} onChange={() => setFilterCheck(setB, b, "blue")}/>
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      }
      <div className="filter-descriptions">
        {
          filter === "temperature" &&
          <>
            <div className="descriptions">
              <h6 className="general-desc">*The darker the color, the further the temperature vaires from the expected value.</h6>
              <h6 className="red-desc">*Red indicates that the temperature at the sensor location is above the recipe specifications. It might be necessary to cool this area down.</h6>
              <h6 className="blue-desc">*Blue indicates that the temperature at the sensor location is below the recipe specifications. It might be necessary to heat this area up.</h6>
            </div>
          </>
        }
        {
          filter === "humidity" &&
          <>
            <div className="descriptions">
              <h6 className="general-desc">*The darker the color, the further the humidity vaires from the expected value.</h6>
              <h6 className="red-desc">*Red indicates that the humidity at the sensor location is above the recipe specifications. It might be necessary to dehumidify this area.</h6>
              <h6 className="blue-desc">*Blue indicates that the humidity at the sensor location is below the recipe specifications. It might be necessary to humidify this area.</h6>
            </div>
          </>
        }
        {
          filter === "co2" &&
          <>
            <div className="descriptions">
              <h6 className="general-desc">*The darker the color, the further the CO<sub>2</sub> vaires from the expected value.</h6>
              <h6 className="red-desc">*Red indicates that the CO<sub>2</sub> at the sensor location is above the recipe specifications. It might be necessary to ventilate this area.</h6>
              <h6 className="blue-desc">*Blue indicates that the CO<sub>2</sub> at the sensor location is below the recipe specifications. It might be necessary to suppliment this area with CO<sub>2</sub>.</h6>
            </div>
          </>
        }
        {
          filter === "vpd" &&
          <>
            <div className="descriptions">
              <h6 className="general-desc">*The darker the color, the further the VPD vaires from the expected value.</h6>
              <h6 className="red-desc">*Red indicates that the VPD at the sensor location is above the recipe specifications. This will cause water stress and plants to dry out and die.</h6>
              <h6 className="blue-desc">*Blue indicates that the VPD at the sensor location is below the recipe specifications. This will cause mold and other diseases for plants.</h6>
            </div>
          </>
        }
        {
          filter === "all-air" &&
          <>
            <div className="descriptions">
              <h6 className="general-desc">*The darker the color, the further the weighted average of all enviromental factors vaires from the expected value.</h6>
              <h6 className="red-desc">*Red indicates that the weighted average of all enviromental factors at the sensor location is above the recipe specifications. Please check which of the enviromental factors are exceeding.</h6>
              <h6 className="blue-desc">*Blue indicates that the weighted average of all enviromental factors at the sensor location is below the recipe specifications. Please check which of the enviromental factors are lacking.</h6>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default Filters
