import React, { useState, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { validate_email, hideErrors } from "../../services/validate.js"
import { postData, getData } from "../../services/sendData.js"
import { getUser } from "../../services/auth.js"
import Loader from "react-loader-spinner";
import Select from 'react-select'

import "../css/org/team.scss"

var userType = getUser().userType;

const AddMember = (props) => {
  function compileAccessList() {
    var access_list = [];
    for (var i = 0; i < props.member.Rooms.length; i++) {
      var item = {label: "", value: ""}
      item.value = props.member.Rooms[i].ID.toString();
      for (var k = 0; k < props.member.Facilities.length; k++) {
        if(props.member.Facilities[k].ID === props.member.Rooms[i].FacilityID) {
          item.label = props.member.Facilities[k].Name + " - " + props.member.Rooms[i].Name
        }
      }
      access_list.push(item)
    }
    return access_list
  }

  const [phone_number, set_phone_number] = useState(props.member != null ? props.member.Phone : "");
  const [accessList, setAccessList] = useState([]);
  const [newAccessList, setNewAccessList] = useState(props.member == null ? undefined : compileAccessList());
  const [newPriviledge, setNewPriviledge] = useState(props.member == null ? undefined : {label: props.member.Privilege.charAt(0).toUpperCase() + props.member.Privilege.slice(1), value: props.member.Privilege});
  const [loading, setLoading] = useState(false);

  async function getAccessList(){
    var data = getData('getAccessList')
    await data.then(function(result) {
      if (result['status'] == 200) {
        setAccessList(result['data'])
        setLoading(false)
       }
    });
  }

  useEffect(() => {
    setLoading(true)
    getAccessList()
  }, [])

  function collectAndValidateFormData() {
    var hasErr = false;
    var first_name = document.getElementById('first-name').value;
    var last_name = document.getElementById('last-name').value;
    var email = document.getElementById('email').value;
    var phone = phone_number;
    var privilege;
    var access_list;
    if(newPriviledge != undefined || newAccessList != undefined) {
      privilege = newPriviledge.value;
      access_list = newAccessList;
    }
    // if(first_name.length === 0 || last_name.length === 0 || email.length === 0 || phone === undefined || newPriviledge === undefined) {
    //   hasErr = true;
    //   document.getElementById('form-nc').style.display = 'block';
    //   return;
    // }
    if(first_name.length === 0 || last_name.length === 0 || newPriviledge === undefined) {
      hasErr = true;
      document.getElementById('form-nc').style.display = 'block';
      return;
    }
    // if(!validate_email(email)) {
    //   hasErr = true;
    //   document.getElementById('invalid-email').style.display = 'block';
    //   return;
    // }
    // if(!isValidPhoneNumber(phone)) {
    //   hasErr = true;
    //   document.getElementById('invalid-pn').style.display = 'block';
    //   return;
    // }

    if(!hasErr) {
      hideErrors();
      var data = {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'phone_number': phone_number,
        'privilege': privilege,
        'access_list': access_list
      }
      console.log(data);
      var submit = document.getElementById('submit');
      submit.style.display = 'none';
      var load = document.getElementById('add-member-load');
      load.style.display = 'inline';
      var response;
      if (props.member != null) {
        data['id'] = props.member.ID
        response = postData("editOrgMember", data)
      } else {
        response = postData("addOrgMember", data)
      }
      response.then(function(result) {
        if (result["status"] === 200) {
          props.getOrg()
          props.addMember(false)
          props.setMember(null)
        } else {
          hasErr = true;
          document.getElementById('form-nc').style.display = 'block';
          var addMember = document.getElementById('submit');
          addMember.style.display = 'inline';
          var load = document.getElementById('add-member-load');
          load.style.display = 'none';
          return;
        }
      })
    }
  }

  const privilege_options = [
    { value: 'owner', 'label': 'Owner'},
    { value: 'supervisor', 'label': 'Supervisor'},
    { value: 'operator', 'label': 'Operator'},
  ]

  function handleAccessChange(selectedOptions) {
    setNewAccessList(selectedOptions);
  }

  function handlePriviledgeChange(selectedOptions) {
    setNewPriviledge(selectedOptions);
  }

  return (
    <>
      <div id="add-member-div">
        {
          props.member == null &&
          <>
            <h3>Add Member</h3>
            <div class="add-member-form">
              <label>First Name</label>
              <input type="text" id="first-name" name="first-name" placeholder="John" required/>

              <label>Last Name</label>
              <input type="text" id="last-name" name="last-name" placeholder="Smith" required/>

              <label>Email</label>
              <input type="email" id="email" name="email" placeholder="john.smith@email.com (optional)"/>

              <label>Phone Number</label>
              <PhoneInput
                id="phone_number"
                placeholder="Phone Number"
                defaultCountry="US"
                value={phone_number}
                onChange={set_phone_number} required/>

              <label>Priviledge</label>
              <Select options={privilege_options} onChange={handlePriviledgeChange} />

              {
                userType === 'owner' &&
                <>
                  <label>Access</label>
                  {
                    loading ?
                    <>
                      <div className="select-loader">
                          <Loader type="Oval" color="#2ecc71" height={20} width={20}/>
                      </div>
                    </> :
                    <>
                      <Select options={accessList} onChange={handleAccessChange} isMulti />
                    </>
                  }
                </>
              }
            </div>
            <button id="submit" className="btn" onClick={() => { collectAndValidateFormData(); }}>
              Invite
            </button>
          </>
        }
        {
          props.member != null &&
          <>
            <h3>Edit Member</h3>
            <div class="add-member-form">
              <label>First Name</label>
              <input type="text" id="first-name" name="first-name" defaultValue={props.member.FirstName} required/>

              <label>Last Name</label>
              <input type="text" id="last-name" name="last-name" defaultValue={props.member.LastName} required/>

              <label>Email</label>
              <input type="email" id="email" name="email" defaultValue={props.member.Email} required/>

              <label>Phone Number</label>
              <PhoneInput
                id="phone_number"
                placeholder={props.member.Phone}
                defaultCountry="US"
                value={phone_number}
                onChange={set_phone_number} required/>

              <label>Priviledge</label>
              <Select options={privilege_options} defaultValue={newPriviledge} onChange={handlePriviledgeChange} />

              {
                userType === 'owner' &&
                <>
                  <label>Access</label>
                  {
                    loading ?
                    <>
                      <div className="select-loader">
                          <Loader type="Oval" color="#2ecc71" height={20} width={20}/>
                      </div>
                    </> :
                    <>
                      <Select options={accessList} defaultValue={newAccessList} onChange={handleAccessChange} isMulti />
                    </>
                  }
                </>
              }
            </div>
            <button id="submit" className="btn" onClick={() => { collectAndValidateFormData(); }}>
              Save
            </button>
          </>
        }
        <button id="add-member-load" className="btn">
          <Loader type="Oval" color="#ffffff" height={20} width={20}/>
        </button>
        <button id="add-member-cancel" className="btn" onClick={() => { props.addMember(false); }}>
          Cancel
        </button>
        <h6 className="error" id="form-nc">Form not complete.</h6>
        <h6 className="error" id="invalid-email">Invalid email.</h6>
        <h6 className="error" id="invalid-pn">Invalid phone number.</h6>
      </div>
    </>
  )
}

export default AddMember
