import React, { useState, useEffect } from "react"
import { getData } from "../../services/sendData.js"
import { Scrollbars } from 'react-custom-scrollbars';
import Loading from "../utils/loading.js"
import parse from 'html-react-parser';
import moment from 'moment'

import "../css/notifications/notifications.scss"

var MOISTURE_LEVEL_NOTIFICATION = "data-moisture-level-"
var MOISTURE_FEED_NOTIFICATION = "data-moisture-feed-"
var LIGHTS_NOTIFICATION = "data-lights"
var ENV_TEMPERATURE_NOTIFICATION = "data-env-temp"
var ENV_HUMIDITY_NOTIFICATION = "data-env-hum"
var ENV_CO2_NOTIFICATION = "data-env-co2"
var ENV_VPD_NOTIFICATION = "data-env-vpd-"

const Notification = ({notification}) => {
  var date = moment(notification["CreatedAt"]).local().format('M/D/YY - h:mm A');

  function notificationType(type) {
    if (type.includes(MOISTURE_FEED_NOTIFICATION) && type.includes(MOISTURE_LEVEL_NOTIFICATION)) {
      return "Moisture"
    } else if (type.includes(LIGHTS_NOTIFICATION)) {
      return "Lights"
    } else if (type.includes(ENV_TEMPERATURE_NOTIFICATION)) {
      return "Temperature"
    } else if (type.includes(ENV_HUMIDITY_NOTIFICATION)) {
      return "Humidity"
    } else if (type.includes(ENV_CO2_NOTIFICATION)) {
      return "CO" + "2".sub()
    } else if (type.includes(ENV_VPD_NOTIFICATION)) {
      return "VPD"
    }

    return type
  }

  function alertType(type) {
    if (type.includes(ENV_VPD_NOTIFICATION)) {
      return <div className="alert-type alert-type-severe">Severe</div>
    } else {
      return <div className="alert-type alert-type-normal">Normal</div>
    }
  }

  return (
    <>
      <tr className="table-row">
        <td>
          {
            alertType(notification['Type'])
          }
        </td>
        <td>{parse(notificationType(notification['Type']))}</td>
        <td>{date}</td>
        <td>Facility: {notification["Device"]["Facility"]["Name"]} <br></br> Room: {notification["Device"]["Room"]["Name"]} <br></br> Identifier: {notification["Device"]["Identifier"]}</td>
        <td>{notification['Message']}</td>
      </tr>
    </>
  )
}

// TODO: SET LIMIT
const Notifications = ({location}) => {
  const [nn, setNN] = useState(0)
  const [notifications, setNotifications] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  async function getUserNotifications(offset){
    var data = getData('getUserNotifications?offset=' + offset.toString());
    await data.then(function(result) {
      if (result['status'] == 200) {
        setNN(result['data'].num_notifications);
        setNotifications(result['data'].notifications)
        setLoading(false);
       }
    });
  }

  function next() {
    setOffset(offset + 10)
  }

  function prev() {
    setOffset(offset - 10)
  }

  useEffect(() => {
    setLoading(true);
    getUserNotifications(0);
  }, [])

  useEffect(() => {
    setLoading(true);
    getUserNotifications(offset);
  }, [offset])

  if (loading) {
    return <Loading />
  }

  return (
    <>
      <div id="notification-content">
        <div id="notification-content-header">
          <h3>Notifications</h3>
        </div>
        <div id="notification-inbox">
          <table>
            <tr id="table-hdr">
              <th width="10%">Severity</th>
              <th width="10%">Type</th>
              <th width="10%">Time</th>
              <th width="10%">Locaton</th>
              <th width="70%">Message</th>
            </tr>
            {
              Array.from(notifications, (e, i) => {
                return (
                  <>
                    <Notification notification={e} />
                  </>
                )
              })
            }
          </table>
        </div>
        <div id="notification-scroller">
          <button className={offset === 0 ? "disabled notification-btn" : "notification-btn"} id="left" onClick={() => prev()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
            </svg>
            Previous
          </button>
          <button className={nn - 10 < offset ? "disabled notification-btn" : "notification-btn"} id="right" onClick={() => next()}>
            Next
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

export default Notifications
