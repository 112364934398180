import React, { useState } from "react"
import { getUser } from "../../services/auth.js"
import parse from 'html-react-parser';
import moment from 'moment'

const Recipe = (props) => {
  const [toggle, setToggle] = useState(true)

  function findStages(recipe) {
    var stages = [];
    for(var i = 0; i < recipe["weeks"].length; i++) {
      var hasStage = false;
      for(var y = 0; y < stages.length; y++) {
        if(recipe["weeks"][i]["stage"] == stages[y]) {
            hasStage = true;
        }
      }
      if(!hasStage) {
        stages.push(recipe["weeks"][i]["stage"]);
      }
    }
    return stages;
  }

  function clockAdjust(clock) {
    var time = parseInt(clock.split(":")[0]);
    let local = moment.utc(clock,'HH:mm').local().format("h:mm A");
    return local;
  }

  function flush(week, label) {
    if(week["flush"] === true) {
      return "<th>" + (label ? "Flush" : "") + "</th>";
    }
    return ""
  }

  function weekSchedule(weeks, stage) {
    var num_weeks = 0;
    var stage_weeks = [];
    var nuts = [];
    for(var i = 0; i < weeks.length; i++) {
      var week = weeks[i];
      if(week["stage"] != stage) {
        continue;
      }
      stage_weeks.push(week);
      num_weeks++;
      for(var y = 0; y < week.nutrients.length; y++) {
        var nut = week.nutrients[y][0];
        var hasNut = false;
        for(var z = 0; z < nuts.length; z++) {
          if(nuts[z] == nut) {
            hasNut = true;
          }
        }
        if(!hasNut) {
          nuts.push(nut);
        }
      }
    }

    var table = "<table className='weeks-table'><tr>";

    table += "<th>Nutrient</th>";
    for(var i = 0; i < num_weeks; i++) {
      var week_number = i+1;
      table += "<th>Week " + week_number.toString() + "</th>";
      table += flush(stage_weeks[i], true);
    }
    table += "</tr>";

    table += "<tr>";
    table += "<th>Watering Time</th>";
    for(var i = 0; i < num_weeks; i++) {
      table += "<td>" + stage_weeks[i]["water-low"] + "-" + stage_weeks[i]["water-high"] + " secs</td>";
      table += flush(stage_weeks[i], false);
    }
    table += "</tr>";

    for(var y = 0; y < nuts.length; y++) {
      table += "<tr>";
      var nut = nuts[y];
      table += "<th>" + nut + "</th>";
      for(var i = 0; i < num_weeks; i++) {
        var hasNut = false;
        for(var z = 0; z < stage_weeks[i]["nutrients"].length; z++) {
          if(stage_weeks[i]["nutrients"][z][0] === nut) {
            hasNut = true;
            table += "<td>" + stage_weeks[i]["nutrients"][z][1] + "</td>";
            table += flush(stage_weeks[i], false);
          }
        }
        if(!hasNut) {
          table += "<td>--</td>";
        }
      }
      table += "</tr>";
    }

    table += "<tr>";
    table += "<th>PPM</th>";
    for(var i = 0; i < num_weeks; i++) {
      table += "<td>" + stage_weeks[i]["co2"] + "</td>";
      table += flush(stage_weeks[i], false);
    }
    table += "</tr>";

    table += "</table>";

    return table;
  }

  return (
    <>
      <div>
        <div className="recipe">
          <div className="recipe-header" onClick={() => {setToggle(!toggle)}}>
            <h4>{props.e.Name}</h4>
            <div className="toggle">
              {
                toggle &&
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              }
              {
                !toggle &&
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
              }
            </div>
          </div>
          {
            !toggle &&
            <>
              <h6 className="creator">By {props.e.Creator.FirstName} {props.e.Creator.LastName}</h6>
              {findStages(props.e.GrowCycle).map((t, c) => {
                return (
                  <>
                    <div className="stage-data">
                      <h5>{t.charAt(0).toUpperCase() + t.slice(1)}</h5>
                      <div className="globals">
                        <h5>Globals</h5>
                        <table className="globals-table">
                          <tr>
                            <th>Metric</th>
                            <th>Value</th>
                          </tr>
                          <tr>
                            <th>Mix Tank</th>
                            <td>{props.e.GrowCycle.globals[t].tank} gallons</td>
                          </tr>
                          <tr>
                            <th>pH</th>
                            <td>{props.e.GrowCycle.globals[t]["ph-low"]} to {props.e.GrowCycle.globals[t]["ph-high"]}</td>
                          </tr>
                          <tr>
                            <th>Temperature</th>
                            <td>{props.e.GrowCycle.globals[t]["temp-low"]}&deg; F to {props.e.GrowCycle.globals[t]["temp-high"]}&deg; F</td>
                          </tr>
                          <tr>
                            <th>Humidity</th>
                            <td>{props.e.GrowCycle.globals[t]["hum-low"]}% to {props.e.GrowCycle.globals[t]["hum-high"]}%</td>
                          </tr>
                          <tr>
                            <th>Lighting</th>
                            <td>Ramp up from {clockAdjust(props.e.GrowCycle.globals[t]["on-start"])} to {clockAdjust(props.e.GrowCycle.globals[t]["on-end"])} ramp {props.e.GrowCycle.globals[t]["on-ramp"]}% every {props.e.GrowCycle.globals[t]["on-ramp-period"]} hour.</td>
                          </tr>
                          <tr>
                            <th>Lighting</th>
                            <td>Ramp down from {clockAdjust(props.e.GrowCycle.globals[t]["off-start"])} to {clockAdjust(props.e.GrowCycle.globals[t]["off-end"])} ramp {props.e.GrowCycle.globals[t]["off-ramp"]}% every {props.e.GrowCycle.globals[t]["off-ramp-period"]} hour.</td>
                          </tr>
                        </table>
                      </div>
                      <div className="weeks">
                        <h5>Schedule</h5>
                        {parse(weekSchedule(props.e.GrowCycle.weeks, t))}
                      </div>
                    </div>
                  </>
                )
              })}
            </>
          }
        </div>
      </div>
    </>
  )
}

class CurrentRecipes extends React.Component {
  userType = getUser().userType;

  searchRecipes() {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("recipe-search");
    filter = input.value.toUpperCase();
    table = document.getElementById("recipe-table");
    tr = table.getElementsByTagName("div");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      var h4 = tr[i].getElementsByTagName("h4")[0];
      if (h4) {
        txtValue = h4.textContent || h4.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  render () {
    return (
      <>
        <div id="recipes">
          <h3>Recipes</h3>
          <div id="recipe-funcs">
            <input type="search" id="recipe-search" className="" name="recipe-search" placeholder="Search Recipes" onKeyUp={this.searchRecipes}/>
            <button id="add-recipe" className="btn" onClick={() => { this.props.setRecipe(true); }} disabled={this.userType != "owner" && this.userType != "supervisor" ? true : false}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-journal-plus" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z"/>
                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"/>
                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"/>
              </svg>
              Create Recipe
            </button>
          </div>
          <div id="recipe-table">
            {
              Array.from(this.props.recipes, (e, i) => {
                return (
                  <Recipe e={e} />
                )
              })
            }
          </div>
        </div>
      </>
    )
  }
}

export default CurrentRecipes
