import React, { useState, useEffect } from "react"
import Back from "../utils/back.js"
import Select from 'react-select'
import Loader from "react-loader-spinner";
import { getUser } from "../../services/auth.js"
import { navigate } from "gatsby"

import "../css/admin/addDevices.scss"

var HOSTNAME = `${process.env.API_URL}`;

const AddDevices = ({backToHome}) => {
  const [deviceType, setDeviceType] = useState(null);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([
    { value: "commander", label: "Commander"},
    { value: "si-thc", label: "SI-THC"},
    { value: "si-p", label: "SI-P"},
    { value: "si-rgb", label: "SI-RGB"},
  ]);
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);

  function handleDeviceTypeChange(deviceType) {
    setDeviceType(deviceType.value);
  }

  function handleFileChange(event) {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  }

  function validateAndCollectForm() {
    var hasErr = false;
    if (deviceType === null || deviceType === "") {
      hasErr = true;
    }

    if (!isFilePicked) {
      hasErr = true;
    }

    const formData = new FormData();
    formData.append('File', selectedFile);
    formData.append('DeviceType', deviceType);

    if (hasErr) {
      document.getElementById('form-nc').style.display = 'block';
    } else {
      document.getElementById('submit').style.display = 'none';
      document.getElementById('add-devices-load').style.display = 'inline';
      var token = getUser()["jwt"];
      fetch(
        HOSTNAME + "admin/addDevices",
        {
          method: 'POST',
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: formData,
          headers: {
            Authorization: "Bearer " + token,
          }
        }
      )
      .then((response) => response.json())
      .then((result) => {
      	backToHome()
      })
      .catch((error) => {
        document.getElementById('submit').style.display = 'inline';
        document.getElementById('add-devices-load').style.display = 'none';
        document.getElementById('form-nc').style.display = 'block';
      });
    }
  }

  return (
    <>
      <div className="add-devices-container">
        <div className="add-devices-content-wrapper">
          <div className="add-devices-content">
            <div className="add-devices-content-header">
              <Back click={backToHome}/>
              <h3>Add Devices</h3>
            </div>
            <div className="add-devices-content-body">
              <h5>CSV format should be [ Board | MAC ]. First row should be titles not data. Board IDs should be unique and not already be registered in the database.</h5>
              <div class="general-form">
                <label>Device Type</label>
                <Select className="select-data" options={deviceTypeOptions} onChange={handleDeviceTypeChange}/>
                <label>MAC - Board Mapping CSV</label>
                <input type="file" name="file" accept=".csv" onChange={handleFileChange}/>
              </div>
            </div>
            <div id="add-devices-btns">
              <button id="submit" className="btn" onClick={() => validateAndCollectForm()}>
                Add
              </button>
              <button id="add-devices-load" className="btn">
                <Loader type="Oval" color="#ffffff" height={17} width={17}/>
              </button>
              <button id="add-devices-cancel" className="btn" onClick={backToHome}>
                Cancel
              </button>
              <h6 className="error" id="form-nc">Form not complete.</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddDevices
