import React, {useState, useEffect} from "react"
import { navigate } from "gatsby"
import Loading from "../utils/loading.js"
import Loader from "react-loader-spinner";
import Select from 'react-select'
import DatePicker from "react-datepicker";
import { getData, postData } from "../../services/sendData.js"
import { hideErrors } from "../../services/validate.js"
import moment from 'moment'

import "react-datepicker/dist/react-datepicker.css";
import "../css/schedule/newGrow.scss"

const NewGrow = () => {
  const [room, setRoom] = useState(null);
  const [roomOptions, setRoomOptions] = useState([]);
  const [recipe, setRecipe] = useState(null);
  const [recipeOptions, setRecipeOptions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  async function grabFormRoomData(){
    var data = getData('getGrowRooms')
    await data.then(function(result) {
      if (result['status'] == 200) {
        setRoomOptions(result['data']);
       }
    });
  }

  async function grabFormRecipeData(){
    var data = getData('getGrowRecipesList')
    await data.then(function(result) {
      if (result['status'] == 200) {
        setRecipeOptions(result['data']);
       }
    });
  }

  async function grabFormInputData(){
    await grabFormRoomData()
    await grabFormRecipeData()
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    grabFormInputData();
  }, [])

  function collectAndValidateForm() {
    var hasErr = false;
    var isValidDate = moment(startDate).isValid();
    if (room == null || recipe == null || !isValidDate) {
      hasErr = true;
      document.getElementById('form-nc').style.display = 'block';
      return;
    }

    var currentDateTime = new Date();
    if (currentDateTime > startDate) {
      hasErr = true;
      document.getElementById('invalid-date').style.display = 'block';
      return;
    }

    if (!hasErr) {
      hideErrors();
    }
    var submit = document.getElementById('submit');
    submit.style.display = 'none';
    var load = document.getElementById('new-grow-load');
    load.style.display = 'inline';

    var data = {
      "room": room,
      "recipe": recipe,
      "start": startDate
    }

    var response = postData("createNewGrow", data);
    response.then(function(result) {
      if (result["status"] === 200) {
        navigate("/portal/schedule");
      } else if (result["status"] === 400) {
        hasErr = true;
        var submit = document.getElementById('submit');
        submit.style.display = 'inline';
        var load = document.getElementById('new-grow-load');
        load.style.display = 'none';
        document.getElementById('date-conflict').style.display = 'block';
        return;
      } else {
        hasErr = true;
        var submit = document.getElementById('submit');
        submit.style.display = 'inline';
        var load = document.getElementById('new-grow-load');
        load.style.display = 'none';
        return;
      }
    })
  }

  function handleRoomChange(room) {
    setRoom(room.value);
  }

  function handleRecipeChange(recipe) {
    setRecipe(recipe.value);
  }

  return (
    <>
      {
        loading ?
        (
          <Loading />
        ) : (
          <div id="dash-content">
            <div id="new-grow-div">
              <h3>New Grow</h3>
              <div id="new-grow-content">
                <div id="new-grow-form">
                  <label>Room</label>
                  <Select options={roomOptions} isOptionDisabled={(option) => option.disabled === "active"} onChange={handleRoomChange} />

                  <label>Recipe</label>
                  <Select options={recipeOptions} onChange={handleRecipeChange} />

                  <label>Start</label>
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} showTimeInput dateFormat="MM/dd/yyyy h:mm aa"/>
                </div>
              </div>
              <div id="new-grow-btns">
                <button id="submit" className="btn" onClick={() => collectAndValidateForm()}>
                  Create
                </button>
                <button id="new-grow-load" className="btn">
                  <Loader type="Oval" color="#ffffff" height={17} width={17}/>
                </button>
                <button id="new-grow-cancel" className="btn" onClick={() => navigate("/portal/schedule")}>
                  Cancel
                </button>
                <h6 className="error" id="form-nc">Form not complete.</h6>
                <h6 className="error" id="invalid-date">Start date time is in the past.</h6>
                <h6 className="error" id="date-conflict">Conflicting grow exists.</h6>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default NewGrow;
