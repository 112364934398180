import React, {useState, useEffect} from "react"
import DataGraph from "../intelligence/dataGraph.js"
import { Scrollbars } from 'react-custom-scrollbars';
import moment from "moment";

import "../css/dashboard/lighting.scss"

const Lighting = ({roomID, roomData, identifier, scope, back, forward}) => {
  const [showGraph, setShowGraph] = useState(false)
  const [sensor, setSensor] = useState(null)
  const [position, setPosition] = useState("")
  const sectionData = roomData() === undefined || roomData()[identifier] === undefined ? undefined : roomData()[identifier];

  function scale(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  function findLightingValues(value) {
    if (sectionData === undefined) {
      return 0
    }
    if (sectionData['lightings'] === null) {
      return 0
    }
    var avg = calculateMetricAverage(sectionData['lightings'], value);
    if (value == "r" || value == "b" || value == "g") {
      return avg/256;
    }
    if (isNaN(avg)) {
      return 0;
    }
    return avg;
  }

  function calculateMetricAverage(list, metric, position=null) {
    var amount = 0;
    var sum = 0;
    for (var m = 0; m < list.length; m++) {
      var diff_min = moment.duration(moment().diff(moment(list[m]['created_at']).local())).asMinutes()
      if (diff_min < 15) {
        if (position == null) {
          sum += list[m][metric];
          amount++;
        } else {
          if (list[m]["position"] == position) {
            sum += list[m][metric];
            amount++;
          }
        }
      }
    }

    if(amount === 0) {
      return -1
    }

    return sum/amount;
  }

  function filterStyle(filter) {
    if (filter == "blue"){
      var value = findLightingValues("b")
      var blue = value != undefined ? value : 0
      return {
        backgroundColor: 'rgba(0, 0,' + blue.toString() + ', 0.7)'
      }
    } else if (filter == "green") {
      var value = findLightingValues("g")
      var green = value != undefined ? value : 0
      return {
        backgroundColor: 'rgba(0,' + green.toString() + ', 0, 0.7)'
      }
    } else if (filter == "red") {
      var value = findLightingValues("r")
      var red = value != undefined ? value : 0
      return {
        backgroundColor: 'rgba(' + red.toString() + ', 0, 0, 0.7)'
      }
    } else if (filter == "color") {
      var redV = findLightingValues("r")
      var greenV = findLightingValues("g")
      var blueV = findLightingValues("b")
      var red = redV != undefined ? redV : 0
      var green = greenV != undefined ? greenV : 0
      var blue = blueV != undefined ? blueV : 0
      return {
        backgroundColor: 'rgba(' + red.toString() + ', ' + green.toString() + ', ' + blue.toString() + ', 0.7)'
      }
    } else if (filter == "lux") {
      var luxV = findLightingValues("lux")
      var lux = luxV != undefined ? luxV : 0
      return {
        backgroundColor: 'rgba(255, 234, 167, ' + scale(lux, 0, 50000, 0, 1).toString() + ')'
      }
    }
  }

  function rgb2hsv (r,g,b) {
    var computedH = 0;
    var computedS = 0;
    var computedV = 0;

    //remove spaces from input RGB values, convert to int
    var r = parseInt( (''+r).replace(/\s/g,''),10 );
    var g = parseInt( (''+g).replace(/\s/g,''),10 );
    var b = parseInt( (''+b).replace(/\s/g,''),10 );

    if (r === -1 || g === -1 || b === -1) {
      return [-1, -1, -1]
    }

    if ( r==null || g==null || b==null ||
        isNaN(r) || isNaN(g)|| isNaN(b) ) {
      alert ('Please enter numeric RGB values!');
      return;
    }
    if (r<0 || g<0 || b<0 || r>255 || g>255 || b>255) {
      alert ('RGB values must be in the range 0 to 255.');
      return;
    }
    r=r/255; g=g/255; b=b/255;
    var minRGB = Math.min(r,Math.min(g,b));
    var maxRGB = Math.max(r,Math.max(g,b));

    // Black-gray-white
    if (minRGB==maxRGB) {
      computedV = minRGB;
      return [0,0,computedV];
    }

    // Colors other than black-gray-white:
    var d = (r==minRGB) ? g-b : ((b==minRGB) ? r-g : b-r);
    var h = (r==minRGB) ? 3 : ((b==minRGB) ? 1 : 5);
    computedH = 60*(h - d/(maxRGB - minRGB));
    computedS = (maxRGB - minRGB)/maxRGB;
    computedV = maxRGB;
    return [computedH,computedS,computedV];
  }

  function hueToWavelength(hue) {
    if (hue >= 0 && hue <= 270) {
      var wavelength = 620 - 170 / 270 * hue;
      return wavelength.toString();
    }
    return "--";
  }

  return (
    <>
      <div id="lighting-container">
        {
          showGraph &&
          <>
            <DataGraph scope={scope} sensor={sensor} hideGraph={() => {setShowGraph(false)}} identifier={identifier} position={position} room={roomID}/>
          </>
        }
        {
          !showGraph &&
          <>
            <div id="lighting-content-wrapper">
              <div id="lighting-content">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16" onClick={() => back()}>
                  <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                </svg>
                <h5>Lighting</h5>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16" onClick={() => forward()}>
                  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
                  <div id="lighting-list-container">
                    <div id="lighting-metrics">
                      <div id="colorTemp" className="color">
                        <h6 className="color-title">Color Temperature</h6>
                        <h6 className="wavelength-title">{findLightingValues("colorTemp") === -1 ? "--" : findLightingValues("colorTemp")}</h6>
                      </div>
                      <div id="lux" className="color" style={filterStyle("lux")}>
                        <h6 className="color-title">Lux</h6>
                        <h6 className="wavelength-title">lx: {findLightingValues("lux") === -1 ? "--" : findLightingValues("lux")} lux</h6>
                      </div>
                      <div id="color" className="color" style={filterStyle("color")}>
                        <h6 className="color-title">Color</h6>
                      </div>
                      <div id="r" className="color" style={filterStyle("red")}>
                        <h6 className="color-title">Red</h6>
                        <h6 className="wavelength-title">&lambda;: {hueToWavelength(rgb2hsv(findLightingValues("red"), 0, 0)[0])} nm</h6>
                      </div>
                      <div id="g" className="color" style={filterStyle("green")}>
                        <h6 className="color-title">Green</h6>
                        <h6 className="wavelength-title">&lambda;: {hueToWavelength(rgb2hsv(0, findLightingValues("green"), 0)[0])} nm</h6>
                      </div>
                      <div id="b" className="color" style={filterStyle("blue")}>
                        <h6 className="color-title">Blue</h6>
                        <h6 className="wavelength-title">&lambda;: {hueToWavelength(rgb2hsv(0, 0, findLightingValues("blue"))[0])} nm</h6>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  )
}

export default Lighting
