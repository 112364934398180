import React, { useState } from "react"
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { getUser } from "../../services/auth.js"

import "../css/org/team.scss"

var userType = getUser().userType;

const Team = (props) => {
  function formatUserAccess(user) {
    var facilities = user.Facilities
    var rooms = user.Rooms
    var access = ``
    for(var i = 0; i < facilities.length; i++) {
      var facilityName = facilities[i].Name
      var facilityId = facilities[i].ID
      var facilityRooms = []
      for(var k = 0; k < rooms.length; k++) {
        if(rooms[k].FacilityID == facilityId){
          facilityRooms.push(rooms[k].Name)
        }
      }
      var facilityRoomsString = facilityRooms.join(", ")
      if (facilityRoomsString != ``) {
        facilityRoomsString = `(` + facilityRoomsString + `)`
      }
      if (access != ``) {
        access += `\n`
      }
      access += facilityName + ` ` + facilityRoomsString
    }
    return access
  }

  function searchOrg() {
    // Declare variables
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("org-search");
    filter = input.value.toUpperCase();
    table = document.getElementById("org-table");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByClassName("userName")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  return (
    <>
      <div id="team">
        <h3>Organization</h3>
        <div id="org-funcs">
          <input type="search" id="org-search" className="" name="org-search" placeholder="Search Organization" onKeyUp={searchOrg}/>
          <button id="add-member" className="btn" onClick={() => props.addMember(true)} disabled={userType != "owner" && userType != "supervisor" ? true : false}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person-plus" viewBox="0 0 16 16">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
              <path fill-rule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
            </svg>
            Add Member
          </button>
        </div>
        <div id="org-container">
          <table id="org-table">
            <tr id="org-table-header">
              <th>Priviledge</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Access</th>
              <th></th>
            </tr>
            {
              Array.from(props.team, (e, i) => {
                return (
                  <tr className="team-member">
                    <td>{e.Privilege.charAt(0).toUpperCase() + e.Privilege.slice(1)}</td>
                    <td className="userName">{e.FirstName + " " + e.LastName}</td>
                    <td>{e.Email}</td>
                    <td>{formatPhoneNumberIntl(e.Phone)}</td>
                    <td>{formatUserAccess(e)}</td>
                    {e.Privilege != "owner" && userType != "operator" &&
                      <td className="view-member" onClick={() => {props.clickMember(i)}}>View</td>
                    }
                  </tr>
                )
              })
            }
          </table>
        </div>
      </div>
    </>
  )
}

export default Team
