import React, { useState, useEffect } from "react"
import { hideErrors } from "../../services/validate.js"
import Loader from "react-loader-spinner";
import { getData, postData } from "../../services/sendData.js"

import "../css/yield/addYield.scss"

/*
TODO:
- Source to support indoor and outdoor 
- Add units for amount
- Add custom types/labels
*/
const AddYield = ({toggleAddYield, room}) => {
    const [sources, setSources] = useState([])
    const [loadingSource, setLoadingSource] = useState(false)

    async function getOrgSources() {
        var data = getData("getOrgSources");
        await data.then(function(result) {
          if (result['status'] == 200) {
            setSources(result['data'])
            setLoadingSource(false)
           }
        });
    }

    function gatherAndValidateForm() {
        var hasErr = false;
        var source = document.getElementById('source').value;
        var amount = document.getElementById('amount').value;
        var type = document.getElementById('type').value;
        var price = document.getElementById('price').value;
        if (source === "" || amount === "" || type === "") {
            hasErr = true;
            document.getElementById('form-nc').style.display = 'block';
            return;
        }

        if (typeof amount != "number" && amount <= 0) {
            hasErr = true;
            document.getElementById('invalid-form').style.display = 'block';
            return;
        }

        hideErrors();
        return {
            "roomID": source,
            "amount": amount,
            "type": type,
            "price": price 
        }
    }

    function addYield() {
        var data = gatherAndValidateForm()
        if (data != null) {
            var submit = document.getElementById('submit');
            submit.style.display = 'none';
            var load = document.getElementById('add-yield-load');
            load.style.display = 'inline';
            var response = postData("addUserYield", data);
            response.then(function(result) {
                if (result["status"] === 200) {
                    toggleAddYield(false)
                } else {
                  document.getElementById('form-nc').style.display = 'block';
                  var addMember = document.getElementById('submit');
                  addMember.style.display = 'inline';
                  var load = document.getElementById('add-yield-load');
                  load.style.display = 'none';
                  return;
                }
            })
        }
    }

    useEffect(() => {
        setLoadingSource(true)
        getOrgSources()
        return () => {
            setSources({});
        };
      }, [])    

    return (
        <>
            <div id="add-yield-content">
                <h3>Add Yield</h3>
                <div id="add-yield-form">
                    <label>Source</label>
                    {
                        loadingSource ?
                        (
                            <>
                                <div className="select-loader">
                                    <Loader type="Oval" color="#2ecc71" height={20} width={20}/>
                                </div>
                            </>
                        ) :
                        (
                            <>
                                <select id="source" required>
                                    <option value="" selected disabled></option>
                                    {
                                        sources.map((object, i) => 
                                            <>
                                                {
                                                    object.rooms.map((sub, k) => 
                                                        <>
                                                            {
                                                                <option value={sub.id} selected={sub.id === room}>{object.name} - {sub.name}</option>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </select>
                            </>
                        )
                    }

                    <label>Amount</label>
                    <input type="number" id="amount" name="amount" min="0" step="0.01" required />

                    <label>Type</label>
                    <select id="type" required>
                        <option value="" selected disabled></option>
                        <option value="alien">Alien</option>
                        <option value="popcorn">Popcorn</option>
                        <option value="mold">Mold</option>
                        <option value="brown">Brown</option>
                        <option value="premi">Premi</option>
                    </select>

                    <label>Price ($)</label>
                    <input type="number" id="price" name="price" step="0.01" min="0" />
                </div>
                <button id="submit" className="btn" onClick={() => addYield()}>
                    Add
                </button>
                <button id="add-yield-load" className="btn">
                    <Loader type="Oval" color="#ffffff" height={20} width={20}/>
                </button>
                <button id="cancel-add-yield" className="btn" onClick={() => { toggleAddYield(false) }}>
                    Cancel
                </button>
                <h6 className="error" id="form-nc">Form not complete.</h6>
                <h6 className="error" id="invalid-form">Invalid form input.</h6>
                <h6 className="error" id="invalid-pn">Invalid phone number.</h6>
            </div>
        </>
    )
}

export default AddYield;
