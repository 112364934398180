import React from "react"

import "../css/intelligence/intelligence.scss"

const Intelligence = () => (
  <>
    <div id="dash-content">
      <h3 id="hdr">Intelligence</h3>
      <div id="intelligence-list">
        <h5>You must complete a full grow cycle to access intelligence.</h5>
      </div>
    </div>
  </>
)

export default Intelligence
