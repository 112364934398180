import React, {useState, useEffect} from "react"
import { Line } from 'react-chartjs-2'
import { getData } from "../../services/sendData.js"
import Loader from "react-loader-spinner";

import "../css/intelligence/dataGraph.scss"

const DataGraph = ({scope, sensor, room, facility, identifier, position, plant, hideGraph, rows, cols}) => {
  const [loading, setLoading] = useState(false)
  const [sensorData, setSensorData] = useState([]);
  const [timeData, setTimeData] = useState([]);
  const [time, setTime] = useState('1M')

  function scale(number, inMin, inMax, outMin, outMax) {
    return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
  }

  function scaleMoistureValues(values) {
    var result = values.map(function (v) {
      return scale(v, 3000, 1200, 0, 1) * 100
    });
    return result;
  }

  function processData(data) {
    if(sensor === "moisture") {
      var moisture_data = [];
      var devices_data = data['devices'];
      for(var i = 0; i < Object.keys(devices_data).length; i++) {
        var color = "#" + Math.floor(Math.random()*16777215).toString(16);
        if(rows === undefined || cols === undefined) {
          var s_data = {
              label: plant.toString().charAt(0).toUpperCase() + sensor.substring(1),
              data: scaleMoistureValues(devices_data[Object.keys(devices_data)[i]][0]),
              fill: false,
              backgroundColor: color,
              borderColor: color,
              lineTension: 0.3,
          };
          moisture_data.push(s_data);
        } else {
          var num_plants = rows * cols;
          for(var y = 0; y < num_plants; y++) {
            var dataset = {
                label: (y+1).toString(),
                data: scaleMoistureValues(devices_data[Object.keys(devices_data)[i]][y]),
                borderColor: color,
                backgroundColor: "#" + Math.floor(Math.random()*16777215).toString(16),
                lineTension: 0.3,
            };
            moisture_data.push(dataset);
          }
        }
      }
      return moisture_data;
    } else {
      var s_data = [
        {
          label: sensor.charAt(0).toUpperCase() + sensor.substring(1),
          data: data["value"],
          fill: false,
          backgroundColor: 'rgb(46, 204, 113)',
          borderColor: 'rgba(46, 204, 113, 0.5)',
          lineTension: 0.3,
        },
      ]
      return s_data;
    }
  }

  async function getSensorData(scope, sensor, room, facility, identifier, position, plant, timeFrame='1M') {
    var getUrl = 'getSensorData?time=' + timeFrame + '&scope=' + scope + '&sensor=' + sensor + '&identifier=' + identifier
    if (facility != undefined) {
      getUrl += '&facility=' + facility
    }
    if (room != undefined) {
      getUrl += '&room=' + room
    }
    if (plant != undefined) {
      getUrl += '&plant=' + plant
    }
    if (position != "") {
      getUrl += "&position=" + position
    }
    var data = getData(getUrl);
    await data.then(function(result) {
      if (result['status'] == 200) {
        setSensorData(processData(result['data']))
        setTimeData(result['data']['time'])
        setLoading(false)
       }
    });
  }

  function changeTimeFrame(time) {
    setLoading(true)
    setTime(time)
    getSensorData(scope, sensor, room, facility, identifier, position, plant, time)
  }

  useEffect(() => {
    setLoading(true)
    getSensorData(scope, sensor, room, facility, identifier, position, plant, time)
  }, [])

  const line_data = {
    labels: timeData,
    datasets: sensorData,
  };

  const line_options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        position: "bottom",
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          text: "Date",
          display: true,
        },
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        title: {
          text: sensor.charAt(0).toUpperCase() + sensor.substring(1),
          display: true,
        },
        grid: {
          display: false
        }
      }
    }
  };

  return (
    <>
      {
       loading ? (
          <>
            <div id="loading-div-graph">
              <Loader type="Oval" color="#2ecc71" height={40} width={40}/>
            </div>
          </>
        ) : (
          <>
            <div id="graph-container">
              <div id="graph-content-wrapper">
                <div id="graph-content">
                  <div id="graph-header">
                    <svg onClick={() => hideGraph()} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
                    </svg>
                    <h5>{sensor.charAt(0).toUpperCase() + sensor.substring(1)}</h5>
                  </div>
                    <Line data={line_data} options={line_options}/>
                </div>
                <ul className="graph-select" id="time-list">
                  <li className={time === '1M' ? "active" : ""} onClick={() => changeTimeFrame('1M')}>1M</li>
                  <li className={time === '3M' ? "active" : ""}  onClick={() => changeTimeFrame('3M')}>3M</li>
                  <li className={time === '6M' ? "active" : ""}  onClick={() => changeTimeFrame('6M')}>6M</li>
                  <li className={time === '1Y' ? "active" : ""}  onClick={() => changeTimeFrame('1Y')}>1Y</li>
                  <li className={time === '5Y' ? "active" : ""}  onClick={() => changeTimeFrame('5Y')}>5Y</li>
                  <li className={time === 'All' ? "active" : ""} onClick={() => changeTimeFrame('All')}>All</li>
                </ul>
              </div>
            </div>
          </>
        )
      }
    </>
  )
}

export default DataGraph
