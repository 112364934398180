import React from "react"
import { getUser } from "../../services/auth.js"

import "../css/dashboard/facilities.scss"

class Rooms extends React.Component {
  userType = getUser().userType;

  render() {
    return (
      <>
        <div id="rooms">
          <div id="rooms-header">
            <h5>Rooms</h5>
            {
              (this.userType == "owner" || this.userType == "supervisor") ?
              <div className="add-room" onClick={() => this.props.addObject(true)}>
                <h6>Create Room +</h6>
              </div> :
              <>
              </>
            }
          </div>
          <div id="room-list">
            {
              this.props.rooms === null &&
              <div id="no-rooms">
                <h6>You have no rooms.</h6>
              </div>
            }
            {
              this.props.rooms != null &&
              this.props.rooms.map((value, index) => {
                return (
                  <div className="room" key={index} onClick={() => {this.props.setter(index)}}>
                    <h6>{value.Name}</h6>
                    {/*<h6 className="room-status">Health: Stable  <span class="dot"></span></h6>*/}
                  </div>
                )
              })
            }
          </div>
        </div>
      </>
    )
  }
}

export default Rooms
